import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  inMemoryPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth';

class AuthService {
  constructor(app) {
    this.firebaseAuth = getAuth(app);
    this.googleProvider = new GoogleAuthProvider();
  }

  login(providerName) {
    const authProvider = this.getProvider(providerName);

    return setPersistence(this.firebaseAuth, browserSessionPersistence).then((e) => {

      return signInWithPopup(this.firebaseAuth, authProvider);
    })
  }

  logout() {
    this.firebaseAuth.signOut();
  }

  onAuthChange = async () => {
    console.log('onAuthChange')

    return await new Promise((resolve, reject) => {
      this.firebaseAuth.onAuthStateChanged((user) => {

        console.log(user)
        if (user) {
          resolve(user)
        } else {
          this.logout()
        }
      });
    })
  }

  getProvider(providerName) {
    switch (providerName) {
      case 'Google':
        return this.googleProvider;
      default:
        throw new Error(`not supported provider: ${providerName}`);
    }
  }
}

export default AuthService;
