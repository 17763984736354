import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readVipData, setPayment } from "../modules/firebase";
import { addOneMonthToDate, toDateFormat } from "../common/common";

const PaymentModal = ({ data, onCancel, isLevel = 1 }) => {
  const dispatch = useDispatch()
  const fbState = useSelector(state => state.firebase);

  const [date, setDate] = useState(toDateFormat(new Date()));
  const [expirationDate, setExpirationDate] = useState(addOneMonthToDate(new Date()));
  const [level, setLevel] = useState(isLevel);
  const [count, setCount] = useState(4);
  const [price, setPrice] = useState(200000);
  const [discount, setDiscount] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [isInput, setIsInput] = useState(false);
  const [isRefund, setIsRefund] = useState(false);

  useEffect(() => {
    handleCalculate()
    dispatch(readVipData({ email: data.email }))
    console.log("fbState.attendanceList", fbState.attendanceList)
  }, [])

  useEffect(() => {
    handleCalculate()
    console.log(date, level, price, count, discount)
  }, [level, count, discount, price])

  useEffect(() => {
    setPrice(getPrice())
  }, [isInput])

  useEffect(() => {
    setPrice(getPrice())
  }, [count])

  const handleCalculate = () => {
    setDiscountedPrice(price - price * (discount / 100));
  };

  const formatPrice = (price) => {
    return price.toLocaleString('ko-KR');
  };

  const getPrice = () => {
    let price;
    if (level === 5) {
      switch (count) {
        case 4:
          price = 160000;
          break;
        case 8:
          price = 320000;
          break;
        case 12:
          price = 480000;
          break;
        default:
          price = 0;
          break;
      }
    } else if (level === 4) {
      switch (count) {
        case 4:
          price = 140000;
          break;
        case 8:
          price = 280000;
          break;
        case 12:
          price = 420000;
          break;
        default:
          price = 0;
          break;
      }
    } else {
      switch (count) {
        case 1:
          price = 60000;
          break;
        case 4:
          price = 200000;
          break;
        case 8:
          price = 400000;
          break;
        case 12:
          price = 600000;
          break;
        default:
          price = 0;
          break;
      }
    }
    return price;
  };

  const handleIsInputChange = (event) => {
    setIsInput(event.target.checked);
  };

  const handleIsRefundChange = (event) => {
    setIsRefund(event.target.checked);
    setPrice(parseInt(0));
  };

  const handlerSetPaymentInfo = () => {

    const paymentInfo = {
      "generation": data.generation,
      "name": data.name,
      "email": data.email,
      "date": date,
      'expirationDate': expirationDate,
      "level": level,
      "count": isRefund ? -count : count,
      "price": price,
      "discount": discount,
      "discountedPrice": discountedPrice,
      "description": description,
      "state": isRefund ? 100 : 1,
    }
    console.log(paymentInfo)

    dispatch(setPayment({ paymentInfo: paymentInfo, level: level }))
    onCancel()
  }

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleExpirationDate = (e) => {
    setExpirationDate(e.target.value);
  };

  const handleLevelChange = (e) => {
    setLevel(parseInt(e.target.value));
  };

  const handleCountChange = (e) => {
    var val = e.target.value <= 0 ? 0 : e.target.value
    console.log(val)
    setCount(parseInt(val));
  };

  const handlePriceChange = (e) => {
    var val = e.target.value <= 0 ? 0 : e.target.value
    setPrice(parseInt(val));
  };

  const handleDiscountChange = (e) => {
    var val = e.target.value <= 0 ? 0 : e.target.value
    setDiscount(parseFloat(val));
  };

  const handleAutoDiscountChange = (val) => {
    console.log('handleAutoDiscountChange', val)
    setDiscount(parseFloat(val));
  };


  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };


  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">쿠폰</h3>
                  <div>
                    <p className="text-sm text-gray-500">구매일 난도 쿠폰 할인률을 설정해 주세요.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-3 border-b border-gray-300 mt-4" />
            <div className="p-2  text-sm font-bold text-gray-500 bg-slate-100">기본 정보</div>
            <div className="mx-2 flex">
              <div>
                <span className="text-xs mb-2 text-gray-500 block">기수</span>
                <span className="mb-2 text-gray-700 ">{data.generation}기</span>
              </div>
              <div className="ml-8">
                <span className="text-xs mb-2 text-gray-500 block">성함</span>
                <span className="mb-2 text-gray-700 ">
                  {fbState.member != null ?
                    <>{data.name === fbState.member.name ? fbState.member.name : ""}</>
                    : ''} </span>
              </div>
              <div className="ml-8">
                <span className="text-xs mb-2 text-gray-500 block">이메일</span>
                <span className="mb-2 text-gray-700 ">{data.email}</span>
              </div>
            </div>



            {console.log("fbState.member", fbState.member)}
            <div className="px-3 border-b border-gray-300 mt-4" />
            <div className="p-2  text-sm font-bold text-gray-500 bg-slate-100">2023년 5시간 챌린지 달성률</div>
            {fbState.member != null ?
              <div className="mx-2 flex">
                <div className="flex-1">
                  <span className="text-xs mb-2 text-gray-500 block h-4">봄</span>
                  <span className="mb-2 text-gray-700 ">
                    {isNaN(parseInt(fbState.member.achievement.s20231)) ? 0 : parseInt(fbState.member.achievement.s20231)}%
                  </span>
                </div>
                <div className=" flex-1">
                  <span className="text-sm mb-2 font-bold  block h-4">여름</span>
                  <span className="mb-2 font-bold">
                    {isNaN(parseInt(fbState.member.achievement.s20232)) ? 0 : parseInt(fbState.member.achievement.s20232)}%
                    {/* {isNaN(parseInt(fbState.member.achievement.s20232)) ? ''
                      : parseInt(fbState.member.achievement.s20232) >= 100 ? handleAutoDiscountChange(20)
                        : parseInt(fbState.member.achievement.s20232) >= 70 ? handleAutoDiscountChange(15)
                          : parseInt(fbState.member.achievement.s20232) >= 50 ? handleAutoDiscountChange(10)
                            : handleAutoDiscountChange(0)} */}
                  </span>
                </div>
                <div className=" flex-1">
                  <span className="text-xs mb-2 text-gray-500 block h-4">가을</span>
                  <span className="mb-2 text-gray-700 ">
                    {isNaN(parseInt(fbState.member.achievement.s20233)) ? 0 : parseInt(fbState.member.achievement.s20233)}%
                  </span>
                </div>
                <div className=" flex-1">
                  <span className="text-xs mb-2 text-gray-500 block h-4">겨울</span>
                  <span className="mb-2 text-gray-700 ">
                    {isNaN(parseInt(fbState.member.achievement.s20234)) ? 0 : parseInt(fbState.member.achievement.s20234)}%
                  </span>
                </div>
              </div>
              : ''}
            <div className="px-3 border-b border-gray-300 mt-4" />

            <label className="px-2">
              <span className="text-gray-700">환불 및 차감</span>
              <input type="checkbox" className="mb-2 ml-2 text-gray-700" checked={isRefund} onChange={handleIsRefundChange} />
            </label>

            <label className={`px-2 ${isRefund ? "hidden" : ""}`}>
              <span className="text-gray-700">직접입력</span>
              <input type="checkbox" className="mb-2 ml-2 text-gray-700" checked={isInput} onChange={handleIsInputChange} />
            </label>

            <div className={`shadow-md sm:rounded-lg w-full ${!isRefund ? "hidden" : ""}`}>
              <div className="w-full grid grid-rows-4 grid-flow-col  text-xs text-center text-gray-500">
                {/* <thead className="text-gray-700 bg-gray-50 w-full border"> */}
                <th scope="col" className="p-2 border bg-slate-100">
                  날짜
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  난도
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  쿠폰
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  환불액
                </th>
                {/* </thead> */}
                {/* <tbody className="bg-white border-b w-full" > */}
                <td className="p-2 border">
                  <input type="date" className="w-full" value={date} onChange={handleDateChange} />
                </td>
                <td className="p-2 border">
                  <select name="level" id="level" className="bg-transparent text-center text-gray-500" value={level} onChange={handleLevelChange}>
                    <option value={5}>스타터</option>
                    <option value={1}>초급</option>
                    <option value={2}>중급</option>
                    <option value={3}>상급</option>
                    <option value={4}>무브먼트</option>
                  </select>
                </td>
                <td className="p-2 border">
                  <input type="number" className="w-[4.5rem] border text-right" onChange={handleCountChange} />
                  <span>회</span>
                </td>
                <td className="p-2 border">
                  <input key="refund" type="number" className="w-[4.5rem] border text-right" onChange={handlePriceChange} />
                  <span>원</span>
                </td>
                {/* </tbody> */}
              </div>
            </div>

            <div className={`shadow-md sm:rounded-lg w-full ${isRefund ? "hidden" : ""}`}>
              <div className="w-full grid grid-rows-[repeat(7,_minmax(0,_1fr));] grid-flow-col text-xs text-center text-gray-500">
                {/* <thead className="text-gray-700 bg-gray-50 w-full border"> */}
                <th scope="col" className="p-2 border bg-slate-100">
                  날짜
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  만료일
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  난도
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  쿠폰
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  원가
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  할인률
                </th>
                <th scope="col" className="p-2 border bg-slate-100">
                  판매가
                </th>
                {/* </thead> */}
                {/* <tbody className="bg-white border-b w-full" > */}
                <td className="p-2 border">
                  <input type="date" className="w-full text-center font-semibold" value={date} onChange={handleDateChange} />
                </td>
                <td className="p-2 border">
                  <input type="date" className="w-full text-center font-semibold" value={expirationDate} onChange={handleExpirationDate} />
                </td>
                <td className="p-2 border">
                  <select name="level" id="level" className="bg-transparent text-center text-gray-500 font-semibold" value={level} onChange={handleLevelChange}>
                    <option value={5}>스타터</option>
                    <option value={1}>초급</option>
                    <option value={2}>중급</option>
                    <option value={3}>상급</option>
                    <option value={4}>무브먼트</option>
                  </select>
                </td>
                <td className="p-2 border">
                  <div className={!isInput ? "block" : "hidden"}>
                    <select name="count" id="count" className="bg-transparent text-center text-gray-500 font-semibold" value={count} onChange={handleCountChange}>
                      <option value={1}>1회</option>
                      <option value={4}>4회</option>
                      <option value={8}>8회</option>
                      <option value={12}>12회</option>
                    </select>
                  </div>
                  <div className={isInput ? "block" : "hidden"}>
                    <input type="number" className="w-14 border text-right" onChange={handleCountChange} />
                    <span>회</span>
                  </div>
                </td>
                <td className="p-2 border font-semibold">
                  <div className={!isInput ? "block" : "hidden"}>
                    {formatPrice(price)}
                  </div>
                  <div className={isInput ? "block" : "hidden"}>
                    <input key="price" type="number" className="w-14 border text-right" onChange={handlePriceChange} />
                    <span>원</span>
                  </div>
                </td>
                <td className="p-2 border">
                  <div className={!isInput ? "block" : "hidden"}>
                    <select name="discount" id="discount" className="bg-transparent text-center text-gray-500 font-semibold" value={discount} onChange={handleDiscountChange}>
                      <option value={0}>없음</option>
                      <option value={5}>5%</option>
                      <option value={10}>10%</option>
                      <option value={15}>15%</option>
                      <option value={20}>20%</option>
                    </select>
                  </div>
                  <div className={isInput ? "block" : "hidden"}>
                    <input type="number" className="w-14 border text-right" onChange={handleDiscountChange} />
                    <span>%</span>
                  </div>
                </td>
                <td className={`p-2 border font-semibold ${discount > 0 ? "text-red-600" : ""}`}>
                  {formatPrice(discountedPrice)}
                  <span>원</span>
                </td>
                {/* </tbody> */}
              </div>
            </div>
            <div>
              <div className="w-full text-xs text-center font-bold text-gray-700 bg-gray-50 py-3 border-b">
                비고
              </div>
              <div className="p-2">
                <textarea type="number" className="w-full border" onChange={handleDescriptionChange} />
              </div>
            </div>
            {/* btn */}
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-end">
              <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handlerSetPaymentInfo}>등록</button>
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={onCancel}>취소</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;