import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readAllAttendance, setPayment } from "../modules/firebase";
import { isExpirationDate, toDateFormat } from "../common/common";

const MemberModal = ({ data, logs, onCancel, isLevel = 1, count }) => {
  const dispatch = useDispatch()
  const fbState = useSelector(state => state.firebase);

  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [level, setLevel] = useState(isLevel);
  const [selectedTap, setSelectedTap] = useState(0);
  const [payLogTable, setPayLogTable] = useState(<></>);
  const [totalLogs, setTotalLogs] = useState([]);
  let attendance = Number(0)
  let absence = Number(0)
  let makeup = Number(0)

  useEffect(() => {
    let tempTable = []
    try {
      logs.data.map((item, idx) => {
        tempTable.unshift(
          <tr className={item.state === 1 ? "border-b border-gray-200" : "border-b border-gray-200 bg-red-50"}>
            <td className="py-4 border-x">
              {item.state === 1 ? "구매" : item.price > 0 ? "환불" : "차감"}
            </td>
            <td className="py-4 border-x">
              {new Date(item.date.seconds * 1000).toLocaleDateString()}
            </td>
            <td className="py-4 border-x">
              {formatLevel(item.level)}
            </td>
            <th className="py-4 border-x">
              <div>
                {item.count}회
              </div>
            </th>
            <td>
              <div>
                {item.state === 1 ? formatPrice(item.price) : "-"}
              </div>
            </td>
            <td className="py-4 border-x">
              <div>
                {item.state === 1 ? item.discount + "%" : "-"}
              </div>
            </td>
            <td className="py-4 border-x">
              {item.state === 1 ? formatPrice(item.discountedPrice) : "-"}
            </td>
            <td className="py-4 border-x">
              {item.description}
            </td>
          </tr>)
      })
      setPayLogTable(tempTable)
    } catch (error) {
      console.log(error)
    }
    dispatch(readAllAttendance({ email: data.email, level: level }))
  }, [level])

  useEffect(() => {
    let tempPaylogs
    switch (level) {
      case 1:
      case 2:
      case 3:
        tempPaylogs = logs.data.filter((item) => item.level !== 4 && item.level !== 5)
        console.log("totalLogs 123", tempPaylogs)
        break;
      case 4:
        tempPaylogs = logs.data.filter((item) => item.level === level)
        console.log("totalLogs 4", tempPaylogs)
        break;
      case 5:
        tempPaylogs = logs.data.filter((item) => item.level === level)
        console.log("totalLogs 5", tempPaylogs)
        break;

      default:
        break;
    }

    setTotalLogs(tempPaylogs.concat(fbState.memberAttendanceData).sort((a, b) => new Date(b.date.seconds * 1000) - new Date(a.date.seconds * 1000)))

  }, [fbState.memberAttendanceData])

  const handleChangeTab = (index) => {
    setSelectedTap(index);
  }
  const formatAttendance = (state, idx) => {
    switch (state) {
      case 1:
        return "출석"
      case 100:
        return "결석"
      case 200:
        return "보강"
      default:
        return false
    }
  };
  const formatPrice = (price) => {
    return price.toLocaleString('ko-KR');
  };

  const formatLevel = (level) => {
    switch (level) {
      case 1:
        return "초급"
      case 2:
        return "중급"
      case 3:
        return "상급"
      case 4:
        return "무브먼트"
      case 5:
        return "스타터"
      default:
        break;
    }
  };

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6 text-blue-600">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">정보</h3>
                  <div>
                    <p className="text-sm text-gray-500">쿠폰 이력 정보 조회</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 border-b border-gray-300 mb-4" />
            <div className="m-4 flex">
              <div>
                <span className="text-xs mb-2 text-gray-500 block">기수</span>
                <span className="mb-2 text-gray-700 ">{data.generation}기</span>
              </div>
              <div className="ml-8">
                <span className="text-xs mb-2 text-gray-500 block">성함</span>
                <span className="mb-2 text-gray-700 "> {data.name}</span>
              </div>
              <div className="ml-8">
                <span className="text-xs mb-2 text-gray-500 block">이메일</span>
                <span className="mb-2 text-gray-700 ">{data.email}</span>
              </div>
            </div>
            <div className="px-3 border-b border-gray-300 mb-4" />

            <div className="flex mb-2">
              <button
                className={`p-2 w-32 rounded-t border font-semibold text-sm flex
              ${selectedTap === 0 ? 'text-blue-700 border-2 border-blue-700 border-b border-b-white' : 'text-gray-700 border-gray-400'}`}
                onClick={() => handleChangeTab(0)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>
                전체내역
              </button>
              <button
                className={`p-2 w-32 rounded-t border font-semibold text-sm flex
              ${selectedTap === 1 ? 'text-blue-700 border-2 border-blue-700 border-b border-b-white' : 'text-gray-700 border-gray-400'}`}
                onClick={() => handleChangeTab(1)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                </svg>
                메모내역
              </button>
              <button
                className={`p-2 w-32 rounded-t border font-semibold text-sm flex
              ${selectedTap === 2 ? 'text-blue-700 border-2 border-blue-700 border-b border-b-white' : 'text-gray-700 border-gray-400'}`}
                onClick={() => handleChangeTab(2)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>
                쿠폰내역
              </button>
              <div className="w-full border-b border-gray-400" />
            </div>

            <div className={`${selectedTap <= 1 ? "block" : "hidden"}`}>
              <button
                className={`inline-block p-2 w-24 rounded-t font-semibold text-sm
              ${level <= 3 ? 'text-blue-700 border-b-blue-700 border-b-2' : 'text-gray-700'}`}
                onClick={() => setLevel(3)}
              >
                파운데이션
              </button>
              <button
                className={`inline-block p-2 w-24 rounded-t font-semibold text-sm
              ${level === 4 ? 'text-blue-700 border-b-blue-700 border-b-2' : 'text-gray-700'}`}
                onClick={() => setLevel(4)}
              >
                올레벨
              </button>
              <button
                className={`inline-block p-2 w-24 rounded-t font-semibold text-sm
              ${level === 5 ? 'text-blue-700 border-b-blue-700 border-b-2' : 'text-gray-700'}`}
                onClick={() => setLevel(5)}
              >
                스타터
              </button>
            </div>

            <div className={`shadow-md sm:rounded-lg w-full ${selectedTap === 0 ? "block" : "hidden"}`}>
              <table className="w-full text-sm text-center text-gray-500 table-fixed">
                <thead className=" text-gray-700 bg-gray-50 w-full border">
                  <th scope="col" className="py-3 border-x">
                    구매일
                  </th>
                  <th scope="col" className="py-3 border-x">
                    만료일
                  </th>
                  <th scope="col" className="py-3 border-x">
                    캠프
                  </th>
                  <th scope="col" className="py-3 border-x">
                    구분
                  </th>
                  <th scope="col" className="py-3 border-x">
                    비고
                  </th>
                </thead>
                <tbody className="bg-white border-b w-full" >
                  {
                    Array.isArray(fbState.memberAttendanceData) && fbState.memberAttendanceData.length > 0 ?
                      totalLogs.map((item, idx) => {
                        if (formatAttendance(item.state) !== false || item.description) {
                          return (
                            <tr className={item.camp ? "border-b border-gray-200" : item.state === 1 ? "border-b border-gray-200 bg-blue-100" : "border-b border-gray-200 bg-red-100"}>
                              <td className="py-2 border-x">
                                {toDateFormat(new Date(item.date.seconds * 1000))}
                              </td>
                              <td className="py-2 border-x">
                                {isExpirationDate(item.date, item.expirationDate)}
                              </td>
                              <td className="py-2 border-x">
                                {
                                  item.camp ? item.camp :
                                    item.state === 1 ? "구매" : item.price > 0 ? "환불" : "차감"
                                }
                              </td>
                              <td className="py-2 border-x">
                                {item.camp ? formatAttendance(item.state, idx) :
                                  formatLevel(item.level) + " - " + item.count + "회"}
                                <div className="hidden">
                                  {item.state === 1 && item.camp ? attendance += 1 : ""}
                                  {item.state === 100 && item.camp ? makeup += 1 : ""}
                                  {item.state === 200 && item.camp ? absence += 1 : ""}
                                  {item.state === 1 ? "구매" : item.price > 0 ? "환불" : "차감"}
                                </div>
                              </td>
                              <td className="py-2 border-x">
                                {item.description}
                              </td>
                            </tr>
                          )
                        }
                      }) : null
                  }

                  <tr className="text-right w-full">
                    <td colSpan={4} className="text-center p-2 font-semibold text-base">
                      전체 : {attendance + makeup + absence}
                      [
                      <span className="bg-green-200 rounded"> 출석 : {attendance} </span> /
                      <span className="bg-yellow-200 rounded"> 보강 : {makeup} </span>/
                      <span className="bg-red-200 rounded"> 결석 : {absence} </span>
                      ]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={`shadow-md sm:rounded-lg w-full ${selectedTap === 1 ? "block" : "hidden"}`}>
              <table className="w-full text-sm text-center text-gray-500 table-fixed">
                <thead className=" text-gray-700 bg-gray-50 w-full border">
                  <th scope="col" className="py-3 border-x">
                    날짜
                  </th>
                  <th scope="col" className="py-3 border-x">
                    캠프
                  </th>
                  <th colSpan={2} scope="col" className="py-3 border-x">
                    메모
                  </th>
                </thead>
                <tbody className="bg-white border-b w-full" >
                  {
                    Array.isArray(fbState.memberAttendanceData) && fbState.memberAttendanceData.length > 0 ?
                      fbState.memberAttendanceData.map((item, idx) => {
                        if (item.description) {
                          return (
                            <tr className={"border-b border-gray-200"}>
                              <td className="py-2 border-x">
                                {new Date(item.date.seconds * 1000).toLocaleDateString()}
                              </td>
                              <td className="py-2 border-x">
                                {item.camp}
                              </td>
                              <td colSpan={2} className="py-2 border-x">
                                {item.description}
                              </td>
                            </tr>
                          )
                        }
                      }) : null
                  }
                </tbody>
              </table>
            </div>

            <div className={`shadow-md sm:rounded-lg w-full ${selectedTap === 2 ? "block" : "hidden"}`}>
              <table className="w-full text-sm text-center text-gray-500 table-fixed">
                <thead className=" text-gray-700 bg-gray-50 w-full border">
                  <th scope="col" className="py-3 border-x">
                    구분
                  </th>
                  <th scope="col" className="py-3 border-x">
                    날짜
                  </th>
                  <th scope="col" className="py-3 border-x">
                    난도
                  </th>
                  <th scope="col" className="py-3 border-x">
                    쿠폰
                  </th>
                  <th scope="col" className="py-3 border-x">
                    금액
                  </th>
                  <th scope="col" className="py-3 border-x">
                    할인률
                  </th>
                  <th scope="col" className="py-3 border-x">
                    판매가
                  </th>
                  <th scope="col" className="py-3 border-x">
                    비고
                  </th>
                </thead>
                <tbody className="bg-white border-b w-full" >
                  {payLogTable}
                </tbody>
              </table>
            </div>
            {/* btn */}
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-end">
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={onCancel}>닫기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberModal;