import { getFirestore, getDocs, collection, getDoc, setDoc, doc, arrayUnion, arrayRemove, updateDoc, orderBy, limit, query, startAt, startAfter, endBefore, where, endAt, Timestamp, deleteDoc, increment, serverTimestamp } from "firebase/firestore";

const attendancePath = (level) => {
  switch (level) {
    case 1:
    case 2:
    case 3:
      return "attendance"

    case 4:
      return "attendanceChoi"

    case 5:
      return "attendanceBasic"

    default:
      break;
  }
}


const couponsPath = (level) => {
  switch (level) {
    case 1:
    case 2:
    case 3:
      return "coupons"

    case 4:
      return "couponsChoi"

    case 5:
      return "couponsBasic"

    default:
      break;
  }
}

class FirebaseRepository {
  constructor(app) {
    this.store = getFirestore(app);
    this.firstDoc = null;
    this.lastDoc = null;
  }

  //challenge
  async readUser(name, challenger) {
    const challengerRef = collection(doc(this.store, "challenger", challenger), "challenge");
    const challengerSnapshot = await getDocs(challengerRef);
    console.log('challengerSnapshot.data()', challengerSnapshot)

    var userData = new Object();
    challengerSnapshot.forEach((doc) => {
      userData[doc.id] = doc.data().total
    });
    userData['name'] = name
    userData['email'] = challenger
    console.log('userData', userData)
    return [userData]
  }

  async updateUser(challenger, week, data) {
    try {
      await setDoc(doc(this.store, "challenger", challenger, "challenge", `${week}`), data)
      console.log("Document written", data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    try {
      if (data.total >= 7) {
        await updateDoc(doc(this.store, "total", `${week}`), {
          one: arrayUnion(challenger),
          seventy: arrayUnion(challenger),
          hundred: arrayUnion(challenger),
        })
      }
      else if (data.total >= 5) {
        await updateDoc(doc(this.store, "total", `${week}`), {
          one: arrayUnion(challenger),
          seventy: arrayUnion(challenger),
          hundred: arrayRemove(challenger),
        })
      }
      else if (data.total >= 1) {
        await updateDoc(doc(this.store, "total", `${week}`), {
          one: arrayUnion(challenger),
          seventy: arrayRemove(challenger),
          hundred: arrayRemove(challenger),
        })
      }
      else if (data.total <= 0) {
        await updateDoc(doc(this.store, "total", `${week}`), {
          one: arrayRemove(challenger),
          seventy: arrayRemove(challenger),
          hundred: arrayRemove(challenger),
        })
      }

    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async readStore() {
    const settingRef = doc(this.store, "setting", "challenge");
    const settingSnapshot = await getDoc(settingRef);
    console.log('settingSnapshot.data()', settingSnapshot.data())

    const totalRef = collection(this.store, "total");
    const totalSnapshot = await getDocs(totalRef);
    console.log('totalSnapshot.data()', totalSnapshot)

    var totalData = new Object();
    totalSnapshot.forEach((doc) => {
      totalData[doc.id] = {
        one: doc.data().one,
        seventy: doc.data().seventy,
        hundred: doc.data().hundred,
      }
    });
    console.log(totalData)

    return { "startWeek": settingSnapshot.data().startWeek, "userCnt": settingSnapshot.data().challengerCnt, "totalData": totalData }
  }

  //vip
  async readVipStore(email) {
    console.log(email)
    const membershipRef = query(collection(this.store, "membership"), where("email", "==", email));

    const membershipSnapshot = await getDocs(membershipRef);

    var membershipData = [];
    membershipSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      membershipData = doc.data()
    });

    console.log(membershipData)
    return membershipData
  }

  async readVipListStore(nextPage = true, reset = false, level = [1, 2, 3]) {
    var membershipRef
    if (this.lastDoc === null || reset) {
      membershipRef = query(collection(this.store, "membership"), where("level", "in", level), limit(10));
    } else {
      nextPage ?
        membershipRef = query(collection(this.store, "membership"), where("level", "in", level), startAfter(this.lastDoc), limit(10))
        : membershipRef = query(collection(this.store, "membership"), where("level", "in", level), endBefore(this.firstDoc), limit(10))
    }

    const membershipSnapshot = await getDocs(membershipRef);

    this.firstDoc = membershipSnapshot.docs[0];
    console.log("first", this.firstDoc.data().name);

    this.lastDoc = membershipSnapshot.docs[membershipSnapshot.docs.length - 1];
    console.log("last", this.lastDoc.data().name);

    var membershipData = [];
    membershipSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      membershipData.push(doc.data())
    });
    console.log(membershipData)
    return membershipData
  }

  async readMembershipStore(nextPage = true, reset = false, level = [1, 2, 3]) {
    var membershipRef
    if (this.lastDoc === null || reset) {
      membershipRef = query(collection(this.store, "membership"), orderBy("generation"));
    } else {
      // nextPage ?
      //   membershipRef = query(collection(this.store, "membership"), where("level", "in", level), startAfter(this.lastDoc), limit(10))
      //   : membershipRef = query(collection(this.store, "membership"), where("level", "in", level), endBefore(this.firstDoc), limit(10))
    }

    const membershipSnapshot = await getDocs(membershipRef);

    this.firstDoc = membershipSnapshot.docs[0];
    console.log("first", this.firstDoc.data().name);

    this.lastDoc = membershipSnapshot.docs[membershipSnapshot.docs.length - 1];
    console.log("last", this.lastDoc.data().name);

    var membershipData = [];
    membershipSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      membershipData.push(doc.data())
    });
    console.log(membershipData)
    return membershipData
  }

  async readNameOrEmailVipListStore(name = false, email = false) {
    var membershipRef
    console.log(name, email)
    if (name !== false) {
      membershipRef = query(collection(this.store, "membership"));
    } else {
      membershipRef = query(collection(this.store, "membership"), where("email", "==", email));
    }
    console.log(membershipRef)
    const membershipSnapshot = await getDocs(membershipRef);

    this.firstDoc = null
    this.lastDoc = null

    var membershipData = [];
    membershipSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data().name);
      if (doc.data().name.endsWith(name)) {
        membershipData.push(doc.data())
      }
    });
    console.log(membershipData)
    return membershipData
  }

  async downloadVipListStore() {
    var membershipRef = query(collection(this.store, "membership"), orderBy("generation"))

    const membershipSnapshot = await getDocs(membershipRef);

    var membershipData = [];
    membershipSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      membershipData.push({
        "createAt": doc.data().createAt,
        "generation": doc.data().generation,
        "name": doc.data().name,
        "email": doc.data().email,
        "level": doc.data().level,
        "lastAt": doc.data().lastAt,
        "inactiveAt": doc.data().inactiveAt,
        "state": doc.data().state,
        "s20221": doc.data().achievement.s20221,
        "s20222": doc.data().achievement.s20222,
        "s20223": doc.data().achievement.s20223,
        "s20224": doc.data().achievement.s20224,
        "s20231": doc.data().achievement.s20231,
        "s20232": doc.data().achievement.s20232,
        "s20233": doc.data().achievement.s20233,
        "s20234": doc.data().achievement.s20234,
      })
    });
    console.log(membershipData)
    return membershipData
  }

  async updateVipListStore(vipList) {
    vipList.map(async (vip, idx) => {
      console.log("Document written : " + idx, vip);
      try {
        const membershipRef = doc(this.store, "membership", vip.email);
        await setDoc(membershipRef, vip)
        console.log("Document written", vip);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    })
  }

  async setVipStore(vip) {
    try {
      const membershipRef = doc(this.store, "membership", vip.email);
      await setDoc(membershipRef, vip)
      console.log("Document written", vip);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async updateVipStore(vip) {
    try {
      const membershipRef = doc(this.store, "membership", vip.email);
      await updateDoc(membershipRef, vip)
      console.log("Document written", vip);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  //plan
  async updatePlan(plan) {

    try {
      await setDoc(doc(this.store, "plan", "newPlan"), { 'plan': plan })
      console.log("Document written", plan);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async readPlan() {
    const planRef = doc(this.store, "plan", "newPlan");
    const planSnapshot = await getDoc(planRef);
    console.log('planSnapshot.data()', planSnapshot.data())

    return planSnapshot.data().plan
  }

  //attendance
  async readAttendanceList(camp) {
    console.log("camp", camp);

    var attendanceRef = query(collection(this.store, "attendanceInfo"), where("camp", "array-contains", camp), orderBy("name"));
    console.log(attendanceRef);

    const attendanceSnapshot = await getDocs(attendanceRef);

    var attendanceList = [];
    attendanceSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      attendanceList.push(doc.data())
    });

    return attendanceList
  }

  async updateAttendanceList(camp, attendanceList) {
    const promises = await attendanceList.map(async (val, idx) => {
      let isCamp = val.camp
      const attendanceRef = doc(this.store, "attendanceInfo", val.email);
      const docSnap = await getDoc(attendanceRef);

      if (docSnap.exists()) {
        if (isCamp) {
          val.camp = [...docSnap.data().camp, camp]
        } else {
          val.camp = docSnap.data().camp.filter(item => item !== camp)
        }
        console.log("doc.data()", docSnap.data().camp)
      } else {
        val.camp = [camp]
      }
      await setDoc(attendanceRef, val)
      console.log(val)
    })


    await Promise.all(promises).then(() => {
      console.log("Promise.all")
    })

    var attendanceRef = query(collection(this.store, "attendanceInfo"), where("camp", "array-contains", camp));
    console.log(attendanceRef);

    const attendanceSnapshot = await getDocs(attendanceRef);

    var readAttendanceList = [];
    attendanceSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      readAttendanceList.push(doc.data())
    });

    return readAttendanceList
  }

  async updateAttendance(attendanceData, state, level) {
    const year = attendanceData.date.getFullYear();
    const month = attendanceData.date.getMonth() + 1;
    const date = attendanceData.date.getDate();
    const email = attendanceData.email
    const camp = attendanceData.camp
    let attendancetCollection = attendancePath(level)
    const attendancetRef = doc(collection(this.store, attendancetCollection), `${email}${year}${month}${date}${camp}`);


    attendanceData.date = Timestamp.fromDate(new Date(attendanceData.date))

    console.log("attendancetRef", attendancetRef, `${email}${year}${month}${date}${camp}`)

    switch (state) {
      case "update":
        return updateDoc(attendancetRef, attendanceData).then(async () => {
          console.log("Document successfully update");
          if (await this.updateCoupon(attendanceData.email, -1, level)) {
            return attendanceData
          }
        })
          .catch((error) => {
            console.error("Error writing document: ", false);
            return setDoc(attendancetRef, attendanceData).then(async () => {
              console.log("Document successfully written!");
              if (await this.updateCoupon(attendanceData.email, -1, level)) {
                return attendanceData
              }
            }).catch((error) => {
              console.error("Error writing document: ", false);
              return false
            })
          });
      case "delete":
        return updateDoc(attendancetRef, { "state": 0 }).then(async () => {
          console.log("Document successfully delete");
          if (await this.updateCoupon(attendanceData.email, 1, level)) {
            return attendanceData
          }
        })
          .catch((error) => {
            console.error("Error writing document: ", false);
          });
      case "description":
        return updateDoc(attendancetRef, attendanceData).then(() => {
          console.log("Document successfully description");
          return attendanceData
        })
          .catch((error) => {
            console.error("Error writing document: ", false);
            return setDoc(attendancetRef, attendanceData).then(() => {
              console.log("Document successfully written!");
              return attendanceData
            }).catch((error) => {
              console.error("Error writing document: ", false);
              return false
            })
          });
      default:

        break;
    }
  }

  async getAttendance(attendanceData, level) {
    const year = attendanceData.date.getFullYear();
    const month = attendanceData.date.getMonth() + 1;
    const date = attendanceData.date.getDate();
    const email = attendanceData.email
    const camp = attendanceData.camp
    let attendancetCollection = attendancePath(level)
    let response

    const attendancetRef = doc(collection(this.store, attendancetCollection), `${email}${year}${month}${date}${camp}`);

    const attendanceSnapshot = await getDoc(attendancetRef);

    if (attendanceSnapshot.exists()) {
      const attendanceData = attendanceSnapshot.data();
      response = attendanceData.state
    } else {
      response = 0
    }

    return response
  }

  async deleteAttendance(attendanceData, level) {
    const year = attendanceData.date.getFullYear();
    const month = attendanceData.date.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
    const date = attendanceData.date.getDate();
    const email = attendanceData.email
    const camp = attendanceData.camp
    let attendancetCollection = attendancePath(level)
    const attendancetRef = doc(collection(this.store, attendancetCollection), `${email}${year}${month}${date}${camp}`);

    attendanceData.date = Timestamp.fromDate(new Date(attendanceData.date))

    console.log("attendancetRef", attendancetRef, `${email}${year}${month}${date}${camp}`)

    return updateDoc(attendancetRef, { "state": 0 }).then(() => {
      console.log("Document successfully update");
      this.updateCoupon(attendanceData.email, 1)
      return attendanceData
    })
      .catch((error) => {
        console.error("Error writing document: ", false);
      });
  }

  async readAttendance(email, date, level,) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const startOfMonth = new Date(year, month - 1, 1);
    const endOfMonth = new Date(year, month, 0);
    let attendancetCollection = attendancePath(level)

    console.log("readAttendance", "[" + email + "]", year, month, startOfMonth, endOfMonth)
    var attendanceRef = query(
      collection(this.store, attendancetCollection),
      where("date", ">=", startOfMonth),
      where("date", "<=", endOfMonth),
      where("email", "==", email))
    console.log(attendanceRef);

    const attendanceSnapshot = await getDocs(attendanceRef);
    console.log(attendanceSnapshot)
    var attendanceDataList = [];
    attendanceSnapshot.forEach((doc) => {
      console.log("readAttendanceData", doc.id, " => ", doc.data());
      attendanceDataList.push(doc.data())
    });

    return { "email": email, "data": attendanceDataList }
  }

  async readAllAttendance(email, level) {
    let attendancetCollection = attendancePath(level)

    console.log("readAllAttendance", "[" + email + "]", level)
    var attendanceRef = query(
      collection(this.store, attendancetCollection),
      where("email", "==", email),
      orderBy("date"))
    console.log(attendanceRef);

    const attendanceSnapshot = await getDocs(attendanceRef);
    console.log(attendanceSnapshot)
    var attendanceDataList = [];
    attendanceSnapshot.forEach((doc) => {
      console.log("readAllAttendance", doc.id, " => ", doc.data());
      attendanceDataList.push(doc.data())
    });

    return attendanceDataList
  }




  //coupons
  async updateCoupon(email, count, level) {
    let couponsCollection = couponsPath(level)
    const couponsRef = doc(collection(this.store, couponsCollection), email);
    console.log("couponsRef", couponsRef)
    if ((await getDoc(couponsRef)).exists()) {
      console.log("doc이 이미 존재하는 경우에는 update 실행", email)
      return await updateDoc(couponsRef, { count: increment(parseInt(count)) }).then(async () => {
        return true
      });
    } else {
      console.log("doc이 존재하지 않는 경우에는 set으로 새로운 문서 생성", email)
      return await setDoc(couponsRef, { count: 0 }).then(async () => {
        return await updateDoc(couponsRef, { count: increment(parseInt(count)) }).then(async () => {
          return true
        });
      });
    }
  }

  async setCoupon(email, count, level) {
    let couponsCollection = couponsPath(level)
    const couponsRef = doc(collection(this.store, couponsCollection), email);
    console.log("couponsRef", couponsRef)
    await setDoc(couponsRef, { count: count }).then(async () => {
      console.log('Document successfully written!', true);
      return true;
    }).catch((error) => {
      console.error("Error writing document: ", error);
    });;
  }


  async readCoupon(email, level) {
    let couponsCollection = couponsPath(level)
    const couponsRef = doc(collection(this.store, couponsCollection), email);
    console.log(couponsRef);

    const couponsSnapshot = await getDoc(couponsRef)

    const data = {
      "email": couponsSnapshot.id,
      "count": couponsSnapshot.data().count
    }
    return data
  }

  async readCouponsList(level) {
    let couponsCollection = couponsPath(level)
    var couponsRef = query(collection(this.store, couponsCollection));
    console.log("couponsList", couponsRef, couponsCollection, level);

    const couponsSnapshot = await getDocs(couponsRef);

    var couponsList = [];
    couponsSnapshot.forEach((doc) => {
      const data = {
        "email": doc.id,
        "count": doc.data().count
      }
      couponsList.push(data)

      console.log("couponsSnapshot", doc.id, " => ", doc.data(), data);
    });

    return couponsList
  }

  //payment
  async setPayment(paymentInfo, level) {
    const paymentRef = doc(collection(this.store, "payment"));
    console.log(paymentRef)

    paymentInfo.expirationDate = Timestamp.fromDate(new Date(paymentInfo.expirationDate))
    paymentInfo.date = Timestamp.fromDate(new Date(paymentInfo.date))

    return setDoc(paymentRef, paymentInfo).then(async () => {
      console.log("Document successfully written!");
      await this.updateCoupon(paymentInfo.email, paymentInfo.count, level)
      paymentInfo.id = paymentRef.id
      return paymentInfo
    })
      .catch((error) => {
        console.error("Error writing document: ", error);
        return false
      });
  }

  async readPayment() {
    var paymentRef = query(collection(this.store, "payment"),
      orderBy("date", "desc"))
    console.log(paymentRef);

    const paymentSnapshot = await getDocs(paymentRef);

    var paymentDataList = [];
    paymentSnapshot.forEach((doc) => {
      const data = doc.data()
      data.id = doc.id
      console.log("readPayment", doc.id, " => ", data);
      paymentDataList.push(data)
    });

    return paymentDataList
  }

  async memberPayment(email, level) {
    var setLevel = [1, 2, 3, 4, 5]
    console.log("setLevel", level, setLevel)
    var paymentRef = query(collection(this.store, "payment"),
      where("level", "in", setLevel),
      where("email", "==", email),
      orderBy("date"))
    console.log(paymentRef);

    const paymentSnapshot = await getDocs(paymentRef);
    console.log(email)
    var paymentDataList = [];
    paymentSnapshot.forEach((doc) => {
      const data = doc.data()
      data.id = doc.id
      console.log("memberPayment", doc.id, " => ", data);
      paymentDataList.push(data)
    });

    return { email: email, data: paymentDataList }
  }

  async deletePayment(paymentInfo, level) {
    const paymentRef = doc(collection(this.store, "payment"), paymentInfo.id);

    return deleteDoc(paymentRef)
      .then(async () => {
        console.log('Document successfully delete!', paymentRef);
        await this.updateCoupon(paymentInfo.email, -paymentInfo.count, level)
        return paymentInfo;
      })
      .catch((error) => {
        console.error("Error writing document: ", false);
        return false;
      });
  }


  async recentPayment(email) {
    console.log(email)
    const paymentRef = query(collection(this.store, "payment"), where("email", "==", email), where("level", "==", 5))
    console.log(paymentRef)
    const paymentSnapshot = await getDocs(paymentRef);

    var paymentData = [];

    paymentSnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      paymentData.push(doc.data())
    });

    console.log(paymentData)
    return paymentData
  }
}

export default FirebaseRepository;
