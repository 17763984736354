import React from "react";
import { Link } from "react-router-dom";

const MenuTab = ({
  text,
  link,
  sel,
  icon
}) => {
  const selCss = "lg:text-base text-sm block border border-blue-500 rounded py-2 px-2 bg-blue-500 hover:bg-blue-700 text-white"
  const unselCss = "lg:text-base text-sm block border border-white rounded hover:border-gray-200 text-blue-500 hover:bg-gray-200 py-2 px-2"
  return (
    <Link className={sel ? selCss : unselCss} to={link}>
      <div className="flex justify-center lg:flex-row flex-col items-center">
        {icon}
        <p className="text-center">{text}</p>
      </div>
    </Link>
  );
}

export default MenuTab;