import React from "react"
import Attendance from "../components/Attendance";
import Menu from "../components/Menu";
import Payment from "../components/Payment";
const AttendancePage = () => {
  return (
    <>
      <div >
        <Attendance />
        <Payment />
      </div>
    </>
  );
}


export default React.memo(AttendancePage);