import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { firebaseApp } from '../service/firebase_config';
import { firebaseAppSub } from '../service/firebase_config_sub';
import FirebaseRepository from '../service/firebase_repository';
import FirebaseStorage from '../service/firebase_storage';
import AuthService from "../service/auth_service";


const firebaseRepository = new FirebaseRepository(firebaseApp);
const firebaseStorage = new FirebaseStorage(firebaseApp);
const authService = new AuthService(firebaseApp);

const initialState = {
  loading: true,
  signData: '',
  signState: false,
  totalData: 0,
  weekData: 0,
  userCnt: 0,
  week: 0,
  startWeek: 20,
  userData: [],
  camp: [],
  signData: '',
  signState: false,
  plan: false,
  couponsList: [],
  attendanceData: [],
  memberPaymentDataList: [],
  memberAttendanceData: [],
  recentPayment: [],
};



//signInCheck 코드
export const signInCheck = createAsyncThunk(
  'firebase/signInState/signInCheck',
  async () => {
    console.log('signInCheck')
    const response = authService.onAuthChange()
    console.log(response)
    return response
  }
)
export const signIn = createAsyncThunk(
  'firebase/signInState',
  async () => {
    const response = await authService.login('Google').then(data => {
      console.log("google", data.user)
      return data
    })
    return response
  }
)

export const signOut = createAsyncThunk(
  'firebase/signInState/logout',
  async () => {
    authService.logout()
  }
)
//main 코드
export const updateUser = createAsyncThunk(
  'firebase/user',
  async ({ user, week, data }) => {
    console.log(user, week, data)
    await firebaseRepository.updateUser(user, week, data)
  }
)


export const readUser = createAsyncThunk(
  'firebase/user',
  async ({ name, email }) => {
    console.log(email)
    const response = await firebaseRepository.readUser(name, email)

    return response
  }
)

export const readData = createAsyncThunk(
  'firebase/read/user',
  async () => {
    console.log('readStartWeek')
    const response = await firebaseRepository.readStore()
    return response
  }
)

export const readCamp = createAsyncThunk(
  'firebase/camp',
  async () => {
    const response = await fetch("https://firebasestorage.googleapis.com/v0/b/xingmove-2dce5.appspot.com/o/camp.json?alt=media", {
      method: "GET"
    })
      .then((response) => response.json())

    console.log('response');
    console.log(response);

    return response
  }
)

export const uploadCamp = createAsyncThunk(
  'firebase/camp/upload',
  async ({ file }) => {
    firebaseStorage.uploadStorage(file)
    return
  }
)

// sub 코드
export const readVipData = createAsyncThunk(
  'firebase/membership/read',
  async ({ email }) => {
    console.log('membershipVipRead')
    const response = await firebaseRepository.readVipStore(email)
    console.log("response", response)
    return response
  }
)
export const readVipListData = createAsyncThunk(
  'firebase/membership/list/read',
  async ({ nextPage, reset, level }) => {
    console.log('membershipVipListRead', nextPage)
    const response = await firebaseRepository.readVipListStore(nextPage, reset, level)
    console.log("response", response)
    return response
  }
)
export const readNameOrEmailVipListStore = createAsyncThunk(
  'firebase/membership/list/read/nameOrEmail',
  async ({ name, email }) => {
    console.log('membershipVipListRead', name, email)
    const response = await firebaseRepository.readNameOrEmailVipListStore(name, email)
    console.log("response", response)
    return response
  }
)
export const updateVipListData = createAsyncThunk(
  'firebase/membership/update',
  async ({ vipList }) => {
    console.log('updateVipListData')
    await firebaseRepository.updateVipListStore(vipList)
  }
)
export const downloadVipListData = createAsyncThunk(
  'firebase/membership/download/vip',
  async () => {
    console.log('downloadVipListData')
    const response = await firebaseRepository.downloadVipListStore()
    console.log("response", response)
    return response
  }
)
export const updateVipData = createAsyncThunk(
  'firebase/membership/update/vip',
  async ({ vip }) => {
    console.log('updateVipData', vip)
    await firebaseRepository.updateVipStore(vip)
  }
)
export const setVipData = createAsyncThunk(
  'firebase/membership/set/vip',
  async ({ vip }) => {
    console.log('setVipData', vip)
    await firebaseRepository.setVipStore(vip)
  }
)

export const updatePlan = createAsyncThunk(
  'firebase/plan/update',
  async ({ plan }) => {
    console.log('updatePlan', plan)
    await firebaseRepository.updatePlan(plan)
  }
)
export const readPlan = createAsyncThunk(
  'firebase/plan/read',
  async () => {
    console.log('readPlan')
    const response = await firebaseRepository.readPlan()
    console.log("response", response)
    return response
  }
)

//멤버쉽
export const readMembershipData = createAsyncThunk(
  'firebase/membership/list/read',
  async ({ nextPage, reset, level }) => {
    console.log('readMembershipData', nextPage)
    const response = await firebaseRepository.readMembershipStore(nextPage, reset, level)
    console.log("response", response)
    return response
  }
)

//출석부 attendanceInfo
export const readAttendanceList = createAsyncThunk(
  'firebase/attendanceInfo/read',
  async ({ camp, year, month, level }, { dispatch }) => {
    console.log('readAttendanceList', camp)
    const response = await firebaseRepository.readAttendanceList(camp)

    if (Array.isArray(response)) {
      response.forEach((val, idx) => {
        dispatch(readAttendanceData({ email: val.email, date: new Date(parseInt(year), parseInt(month - 1), 1), level: level }))
        dispatch(memberPayment({ email: val.email, level: level }))
      })
    }

    return response
  }
)

export const readAllAttendance = createAsyncThunk(
  'firebase/readAllAttendance/read',
  async ({ email, level }, { dispatch }) => {
    const response = await firebaseRepository.readAllAttendance(email, level)
    console.log("response", response)
    // dispatch(memberPayment({ email: email, level: level }))

    return response
  }
)

export const updateAttendanceList = createAsyncThunk(
  'firebase/attendanceInfo/update',
  async ({ camp, attendanceList, level }) => {
    console.log('updateAttendanceList', attendanceList)
    const response = await firebaseRepository.updateAttendanceList(camp, attendanceList)
    return response
  }
)

//attendance
export const setAttendance = createAsyncThunk(
  'firebase/attendance/set',
  async ({ attendanceData, state, level }, { dispatch }) => {
    console.log('setAttendance', attendanceData)

    const getAttendance = await firebaseRepository.getAttendance(attendanceData, level)
    var response
    if (getAttendance !== state) {
      console.log("firebase/attendance/set", getAttendance, state, getAttendance !== state)
      response = await firebaseRepository.updateAttendance(attendanceData, "update", level)
      dispatch(readCoupon({ email: attendanceData.email, level: level }))
    }

    return response
  }
)

export const deleteAttendance = createAsyncThunk(
  'firebase/attendance/delete',
  async ({ attendanceData, level }, { dispatch }) => {
    console.log('attendance', attendanceData)
    const response = await firebaseRepository.updateAttendance(attendanceData, "delete", level)
    dispatch(readCoupon({ email: attendanceData.email, level: level }))
    return response
  }
)

export const setDescription = createAsyncThunk(
  'firebase/description/set',
  async ({ attendanceData, level }) => {
    console.log('attendance', attendanceData)
    const response = await firebaseRepository.updateAttendance(attendanceData, "description", level)
    return response
  }
)

export const readAttendanceData = createAsyncThunk(
  'firebase/attendance/read',
  async ({ email, date, level }) => {
    console.log('readAttendanceData', email, date)
    const response = await firebaseRepository.readAttendance(email, date, level)
    console.log("response", response)
    return response
  }
)

export const getAttendance = createAsyncThunk(
  'firebase/attendance/get',
  async ({ ref, level }) => {
    console.log('getAttendance', ref, level)
    const response = await firebaseRepository.getAttendance(ref, level)
    console.log("response", response)
    return response
  }
)
//Coupon
export const updateCoupon = createAsyncThunk(
  'firebase/coupon/update',
  async ({ email, count, level }, { dispatch }) => {
    console.log('updateCoupon', email, count)
    const response = await firebaseRepository.updateCoupon(email, count, level)
    dispatch(readCoupon({ email: email, level: level }))
    return response
  }
)

export const readCoupon = createAsyncThunk(
  'firebase/coupon/get',
  async ({ email, level }) => {
    console.log('readCoupon', email)
    const response = await firebaseRepository.readCoupon(email, level)
    return response
  }
)

export const readCouponsList = createAsyncThunk(
  'firebase/coupons/gets',
  async ({ level }) => {
    console.log('readCouponsList')
    const response = await firebaseRepository.readCouponsList(level)
    return response
  }
)

//결제
export const setPayment = createAsyncThunk(
  'firebase/payment/set',
  async ({ paymentInfo, level }, { dispatch }) => {
    console.log('paymentInfo', paymentInfo)
    const response = await firebaseRepository.setPayment(paymentInfo, level)
    dispatch(readCoupon({ email: paymentInfo.email, level: level }))
    return response
  }
)

export const deletePayment = createAsyncThunk(
  'firebase/payment/delete',
  async ({ paymentInfo, level }, { dispatch }) => {
    console.log('attendance', paymentInfo)
    const response = await firebaseRepository.deletePayment(paymentInfo, level)
    dispatch(readCoupon({ email: paymentInfo.email, level: level }))
    return response
  }
)

export const readPayment = createAsyncThunk(
  'firebase/payment/read',
  async () => {
    const response = await firebaseRepository.readPayment()
    console.log("response", response)
    return response
  }
)

export const memberPayment = createAsyncThunk(
  'firebase/payment/member/read',
  async ({ email, level }) => {
    const response = await firebaseRepository.memberPayment(email, level)
    console.log("memberPayment", response)
    return response
  }
)
export const recentPayment = createAsyncThunk(
  'firebase/coupons/read',
  async ({ email: email }) => {
    console.log('recentPayment', email)
    const response = await firebaseRepository.recentPayment(email)
    return response
  }
)
// firebaseRTK //
export const firebaseRTK = createSlice({
  name: 'firebase',
  initialState,
  reducers: {
    resetUserData: (state) => {
      state.userData = []
      console.log(state.userData)
    }
  },
  extraReducers: {
    [updatePlan.fulfilled.type]: (state) => {
      console.log('updatePlan', state)
    },
    [readPlan.pending.type]: (state) => {
      state.loading = true;
    },
    [readPlan.fulfilled.type]: (state, action) => {
      console.log('readPlan', action, action.payload)
      state.plan = action.payload
      state.loading = false;
      console.log("readPlan", action.payload)
    },
    [updateUser.fulfilled.type]: (state) => {
      console.log('updateUser', state)
    },
    [readUser.pending.type]: (state) => {
      state.loading = true;
    },
    [readUser.fulfilled.type]: (state, action) => {
      console.log('readUser', action, action.payload)
      var temp = [...state.userData, action.payload[0]]
      state.userData = temp
      state.loading = false;
      console.log("readUserList", temp)
    },
    [readData.pending.type]: (state) => {
      state.loading = true;
    },
    [readData.fulfilled.type]: (state, action) => {
      console.log('readData', action, action.payload)
      state.startWeek = action.payload.startWeek
      state.totalData = action.payload.totalData
      state.userCnt = action.payload.userCnt
      console.log(state.startWeek, state.weekData)
      state.loading = false;
    },
    [readCamp.pending.type]: (state) => {
      state.loading = true;
    },
    [readCamp.fulfilled.type]: (state, action) => {
      console.log('readData', action, action.payload)
      state.camp = action.payload
      console.log(state.camp)
      state.loading = false;
    },
    [uploadCamp.fulfilled.type]: (state) => {
      console.log('uploadCamp', state)
    },
    [readVipData.pending.type]: (state) => {
      // state.loading = true;
    },
    [readVipData.fulfilled.type]: (state, action) => {
      console.log('readVipData', action, action.payload)
      console.log(state.member = action.payload)
      console.log('state', state.member)
      // state.loading = false;
    },
    [readVipListData.pending.type]: (state) => {
      state.loading = true;
    },
    [readVipListData.fulfilled.type]: (state, action) => {
      console.log('readVipListData', action, action.payload)
      state.vipList = action.payload
      console.log('state', state.vipList)
      state.loading = false;
    },
    [downloadVipListData.pending.type]: (state) => {
      // state.loading = true;
    },
    [downloadVipListData.fulfilled.type]: (state, action) => {
      console.log('downloadVipListData', action, action.payload)
      state.downloadVipList = action.payload
      console.log('state', state.downloadVipList)
      // state.loading = false;
    },
    [updateVipListData.pending.type]: (state) => {
      state.loading = true;
    },
    [updateVipListData.fulfilled.type]: (state) => {
      console.log('updateVipListData', state)
      state.loading = false;
    },
    [readNameOrEmailVipListStore.pending.type]: (state) => {
      state.loading = true;
    },
    [readNameOrEmailVipListStore.fulfilled.type]: (state, action) => {
      state.vipList = action.payload
      console.log('state', state.vipList)
      state.loading = false;
    },
    [updateVipData.pending.type]: (state) => {
      state.loading = true;
    },
    [updateVipData.fulfilled.type]: (state) => {
      console.log('updateVipData', state)
      state.loading = false;
    },
    [setVipData.pending.type]: (state) => {
      state.loading = true;
    },
    [setVipData.fulfilled.type]: (state) => {
      console.log('setVipData', state)
      state.loading = false;
    },
    //sign
    [signIn.fulfilled.type]: (state, action) => {
      const url = action.payload.user.email.split('@')
      state.signState = url[1] === 'xingmove.com'
    },
    [signOut.fulfilled.type]: (state, action) => {
    },
    [signInCheck.fulfilled.type]: (state, action) => {
      console.log('console.log(action.payload)')
      console.log(action.payload)
      const url = action.payload.email.split('@')
      state.signState = url[1] === 'xingmove.com'
    },
    //멤버쉽
    [readMembershipData.pending.type]: (state) => {
      // state.loading = true;
    },
    [readMembershipData.fulfilled.type]: (state, action) => {
      console.log('readMembershipData', action, action.payload)
      state.vipList = action.payload
      console.log('state', state.vipList)
      state.loading = false;
    },
    //출석부
    [readAttendanceList.pending.type]: (state) => {
      state.loading = true;
    },
    [readAttendanceList.fulfilled.type]: (state, action) => {
      console.log('readAttendanceList', action, action.payload)
      state.attendanceList = action.payload
      console.log('state', state.attendanceList)
      state.loading = false;
    },
    [updateAttendanceList.pending.type]: (state) => {
      state.loading = true;
    },
    [updateAttendanceList.fulfilled.type]: (state, action) => {
      console.log('updateAttendanceList', state)
      state.loading = false;
      console.log('updateAttendanceList', state.loading)
      state.attendanceList = action.payload
      console.log('state', state.attendanceList)
    },
    [setAttendance.fulfilled.type]: (state, action) => {
      console.log("setAttendance", action.payload)
      if (!(action.payload === false)) {
        const email = action.payload.email
        const attendanceState = action.payload.state
        const data = action.payload
        const existingEmail = state.attendanceData.find(item => item.email === email)
        if (existingEmail) {
          existingEmail.data.forEach(item => {
            if (item.date.toDate().getTime() === action.payload.date.toDate().getTime() && item.camp === action.payload.camp) {
              item["state"] = attendanceState
              console.log("setAttendance.fulfilled.type", email, state)
            } else {
              existingEmail.data.push(action.payload)
            }
          })
        } else {
          existingEmail.push({ email, data })
        }
      }
    },
    [deleteAttendance.fulfilled.type]: (state, action,) => {
      if (!(action.payload === false)) {
        const email = action.payload.email
        const existingEmail = state.attendanceData.find(item => item.email === email)
        existingEmail.data.forEach(item => {
          if (item.date.toDate().getTime() === action.payload.date.toDate().getTime() && item.camp === action.payload.camp) {
            item["state"] = 0
          }
          console.log(item["state"])
        })
      }
    },
    [setDescription.fulfilled.type]: (state, action) => {
      if (!(action.payload === false)) {
      }
    },
    [readAttendanceData.pending.type]: (state) => {
      state.loading = true;
    },
    [readAttendanceData.fulfilled.type]: (state, action) => {
      console.log('readAttendanceData', action, action.payload)
      const { email, data } = action.payload;
      const existingEmail = state.attendanceData.find(item => item.email === email);
      if (existingEmail) {
        existingEmail.data = data; // 데이터 업데이트
      } else {
        console.log('email/data', email, data)
        state.attendanceData.push({ email, data })
      }
      state.loading = false;
    },
    [readAllAttendance.fulfilled.type]: (state, action) => {
      console.log('readAllAttendance', action, action.payload)
      state.memberAttendanceData = action.payload.reverse()
    },
    //쿠폰
    [updateCoupon.fulfilled.type]: (state, action) => {
      console.log('updateCoupon', state)
    },
    [readCoupon.pending.type]: (state) => {
      // state.loading = true;
    },
    [readCoupon.fulfilled.type]: (state, action) => {
      console.log('readCoupon', action, action.payload)
      const idx = state.couponsList.findIndex(obj => obj.email === action.payload.email);
      if (idx !== -1) {
        state.couponsList[idx] = {
          ...state.couponsList[idx],
          count: action.payload.count
        };
        console.log('state', idx, state.couponsList[idx])
      } else {
        state.couponsList.push({
          "email": action.payload.email,
          "count": action.payload.count
        })
      }
      // state.loading = false;
    },
    [readCouponsList.pending.type]: (state) => {
      state.loading = true;
    },
    [readCouponsList.fulfilled.type]: (state, action) => {
      console.log('readCouponsList', action, action.payload)
      state.couponsList = action.payload
      console.log('state', state.couponsList)
      state.loading = false;
    },
    //결재정보
    [setPayment.fulfilled.type]: (state, action) => {
      console.log('setPayment', state)
      if (action.payload !== false) {
        state.paymentDataList.unshift(action.payload)
      }
    },
    [readPayment.pending.type]: (state) => {
      state.loading = true;
    },
    [readPayment.fulfilled.type]: (state, action) => {
      console.log('paymentDataList', action, action.payload)
      state.paymentDataList = action.payload
      console.log('state', state.paymentDataList)
      state.loading = false;
    },
    [memberPayment.pending.type]: (state) => {
      state.loading = true;
    },
    [memberPayment.fulfilled.type]: (state, action) => {
      console.log('memberPaymentDataList', action, action.payload)

      const idx = state.memberPaymentDataList.findIndex(obj => obj.email === action.payload.email);
      if (idx !== -1) {
        state.memberPaymentDataList[idx] = {
          ...state.memberPaymentDataList[idx],
          data: action.payload.data
        };
        console.log('memberPaymentDataListFor', idx, state.memberPaymentDataList[idx])
      } else {
        state.memberPaymentDataList.push(action.payload)
      }
    },
    [deletePayment.fulfilled.type]: (state, action) => {
      console.log(action.payload)
      if (!(action.payload === false)) {
        state.paymentDataList = state.paymentDataList.filter(item => {
          return item.id !== action.payload.id;
        })
      }
    },
    [recentPayment.fulfilled.type]: (state, action) => {
      console.log('recentPayment', action, action.payload)
      if (action.payload) {
        state.recentPayment = action.payload
      }
      console.log('recentPayment', state.recentPayment)
    },
  }
})
export const { resetUserData } = firebaseRTK.actions
