import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { deletePayment, readPayment, } from "../modules/firebase";

const Settlement = () => {
  const dispatch = useDispatch()
  const fbState = useSelector(state => state.firebase);

  useEffect(() => {
    // dispatch(readPayment())
    console.log("fbState.readPayment", fbState.paymentDataList)
  }, [])

  const handlerDeletePayment = (paymentInfo) => {

    const confirmed = window.confirm("확인하시겠습니까?");

    if (confirmed) {
      // dispatch(deletePayment({ paymentInfo: paymentInfo, level: paymentInfo.level }))
      console.log("사용자가 확인을 클릭했습니다.");
    } else {
      // 취소 버튼을 클릭한 경우
      // 원하는 동작 수행
      console.log("사용자가 취소를 클릭했습니다.");
    }

    console.log("handlerDeletePayment", paymentInfo)
  }

  const formatPrice = (price) => {
    return price.toLocaleString('ko-KR');
  };

  // 월별 그룹화 함수
  function groupByMonth(data) {
    return data.reduce((acc, val) => {
      const date = new Date(val.date.seconds * 1000);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 월은 0부터 시작하므로 +1 해줍니다.
      const key = `${year}-${month}`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(val);
      return acc;
    }, {});
  }

  // 월별 결산 계산 함수
  function calculateMonthlySummary(groupedData) {
    const monthlySummary = [];

    for (const key in groupedData) {
      if (groupedData.hasOwnProperty(key)) {
        const monthData = groupedData[key];
        const monthTotal = monthData.reduce((total, val) => {
          if (val.state === 1) {
            return total + val.price;
          } else if (val.state === 2) {
            return total - val.price; // 환불 시 음수로 처리
          } else {
            return total; // 다른 상태는 무시
          }
        }, 0);

        monthlySummary.push({
          month: key,
          total: monthTotal,
        });
      }
    }

    return monthlySummary;
  }

  // 월별 Level 별 결산 계산 함수
  function calculateMonthlyLevelSummary(groupedData) {
    const monthlyLevelSummary = [];

    for (const key in groupedData) {
      if (groupedData.hasOwnProperty(key)) {
        const monthData = groupedData[key];
        const levelSummary = {};

        // Level 별로 데이터 그룹화
        monthData.forEach((val) => {
          if (!levelSummary[val.level]) {
            levelSummary[val.level] = {
              level: val.level,
              total: 0,
              refund: 0,
            };
          }

          if (val.state === 1) {
            levelSummary[val.level].total += val.price;
          } else if (val.state === 100) {
            levelSummary[val.level].refund += val.price;
          }
        });

        // 월별 Level 별 데이터 추가
        for (const levelKey in levelSummary) {
          if (levelSummary.hasOwnProperty(levelKey)) {
            monthlyLevelSummary.push({
              month: key,
              level: levelSummary[levelKey].level,
              total: levelSummary[levelKey].total,
              refund: levelSummary[levelKey].refund,
            });
          }
        }
      }
    }

    return monthlyLevelSummary;
  }
  const levelTolabel = (level) => {
    switch (level) {
      case 1:
        return '초급'

      case 2:
        return '중급'

      case 3:
        return '상급'

      case 4:
        return '무브먼트'

      case 5:
        return '스타터'

      default:
        break;
    }
  }

  return (
    <>
      <div className="px-8 py-2 w-full">
        <div className="shadow-md sm:rounded-lg w-full">
          <table className="w-full text-xs text-center text-gray-500 table-fixed">
            <thead className="text-gray-700 bg-gray-50 ">
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                구분
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                날짜
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                레벨
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                판매
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                환불
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                비고
              </th>
            </thead>
            <tbody className="">
              {Array.isArray(fbState.paymentDataList) && !fbState.loading ?

                calculateMonthlyLevelSummary(groupByMonth(fbState.paymentDataList)).map((item) => (

                  <tr key={item.month} className={"px-2 lg:px-4 py-4 border-b border-gray-200"}>
                    <td className="px-2 lg:px-4 py-4 text-gray-900 text-center">{item.month}</td>
                    <td>{levelTolabel(item.level)}</td>
                    <td>{formatPrice(item.total)}</td>
                    <td>{formatPrice(item.refund)}</td>
                  </tr>
                ))

                // fbState.paymentDataList.map((val, idx) => {
                //   const date = new Date(val.date.seconds * 1000);
                //   return (
                //     <tr className={val.state === 1 ? "border-b border-gray-200" : "border-b border-gray-200 bg-red-50"}>
                //       <td className="px-2 lg:px-4 py-4 text-gray-900 text-center">
                //         {val.state === 1 ? "구매" : val.price > 0 ? "환불" : "차감"}
                //       </td>
                //       <td className="px-2 lg:px-4 py-4 text-gray-900 text-center">
                //         {date.toLocaleDateString()}
                //       </td>
                //       <td className="text-center text-gray-900">
                //         {val.name}
                //       </td>
                //       <td className="text-center text-gray-900">
                //         {val.level === 1 ? "1" : ""}
                //         {val.level === 2 ? "2" : ""}
                //         {val.level === 3 ? "3" : ""}
                //         {val.level === 4 ? "3" : ""}
                //         {val.level === 5 ? "4" : ""}
                //       </td>
                //       <td className="text-center text-gray-900">
                //         {val.count}
                //       </td>
                //       <td className="text-center text-gray-900">
                //         {formatPrice(val.price)}
                //       </td>
                //       <td className="text-center text-gray-900">
                //         {val.state === 1 ? val.discount + "%" : "-"}
                //       </td>
                //       <td className="text-center text-gray-900">
                //         {val.state === 1 ? formatPrice(val.discountedPrice) : "-"}
                //       </td>
                //       <td className="text-center text-gray-900">
                //         {val.description}
                //       </td>
                //       <td className="text-center text-gray-900">
                //         <div className="mx-2 py-2 rounded text-gray-800 bg-red-400 font-semibold cursor-pointer active:bg-red-500 transition duration-300 ease"
                //           onClick={() => { handlerDeletePayment(val) }}
                //         >
                //           취소
                //         </div>
                //       </td>
                //     </tr>
                //   )
                // })
                : <></>
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Settlement;
