import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import axios from 'axios';
import LevelIcon from "./LevelIcon";
import LevelLine from "./LevelLine";
import UpdateModal from "./UpdateModal";
import { useDispatch } from "react-redux";
import { updateVipData } from "../modules/firebase";

const VipList = ({ list, loading }) => {
  const [vipList, setVipList] = useState(<></>)
  const [modal, setModal] = useState("")

  const handlerPopupModal = (val) => {
    setModal(<UpdateModal value={val} onCancel={handlerPopupModalCancel} />)
  }

  const handlerPopupModalCancel = () => {
    setModal("")
  }

  const handlerVipList = async () => {
    const tr = []
    list.map((val, idx) => {
      console.log(val)
      tr.push(
        <div className="bg-white border-b grid grid-cols-6" onClick={() => handlerPopupModal(val)}>
          <th scope="row" className="px-2 lg:px-4 py-4 text-gray-900">
            {val.name}
          </th>
          <td className="px-2 lg:px-4 py-4  overflow-hidden">
            {val.email}
          </td>
          <td className="px-2 lg:px-4 py-4">
            {val.level}
          </td>
          <td className="px-2 lg:px-4 py-4">
            {Object.values(val.achievement).filter(item => item !== null).length}개
          </td>
          <td className="px-2 lg:px-4 py-4 justify-center items-center flex">
            {val.state == "엑티브" ?
              <div className="px-3 py-1 rounded-full lg:text-gray-50 bg-green-500 text-transparent lg:w-auto w-1 overflow-hidden cursor-pointer active:bg-gray-300 transition duration-300 ease">
                ACTIVE
              </div>
              :
              <div className="px-3 py-1 rounded-full lg:text-gray-50 bg-rose-500 text-transparent lg:w-auto w-1 overflow-hidden cursor-pointer active:bg-gray-300 transition duration-300 ease">
                INACTIVE
              </div>
            }
          </td>
          <td className="px-2 lg:px-4 py-4">
            {val.generation}기
          </td>


        </div>
      )
    })
    setVipList(tr)
  }

  useEffect(() => {
    console.log(list)
    handlerVipList()
  }, [])

  return (
    <div className="px-8 py-2 w-full">
      {modal}
      <div className="shadow-md sm:rounded-lg w-full">
        <div className="w-full text-xs text-center text-gray-500 table-fixed">
          <div className=" text-gray-700 bg-gray-50 flex justify-between content-between">
            <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
              VIP
            </th>
            <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
              이메일
            </th>
            <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
              레벨
            </th>
            <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
              업적
            </th>
            <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
              활성화
            </th>
            <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
              구분
            </th>
          </div>
          <div>
            {vipList}
          </div>
        </div>
      </div>
    </div>
  );
}


export default React.memo(VipList);