import React, { useEffect, useState } from "react"
import axios from 'axios';
import { useRef } from "react";


const UserList = ({
  totalData,
  json
}) => {
  const [userList, setUserList] = useState(<></>)
  const [userCnt, setUserCnt] = useState(0)
  const [userTotal, setuserTotal] = useState([0, 0, 0])
  const [rateSeparator, setRateSeparator] = useState("all")
  const [rate, setRate] = useState("all")
  const rateSeparatorRef = useRef()
  const rateRef = useRef()

  const handlerUserList = async () => {
    const data = totalData
    const toJson = json
    const tr = []

    console.log('toJson', toJson)
    console.log(totalData)
    setuserTotal([totalData.one.length, totalData.seventy.length, totalData.hundred.length])
    setUserCnt(toJson.length)

    toJson.map((val, idx) => {
      console.log(val.name, val.email)
      const trLine = <tr className="bg-white border-b hover:bg-gray-300">
        <th scope="row" className="px-4 py-4 text-gray-900whitespace-nowrap">
          {val.name}
        </th>
        <td className="px-4 py-4">
          {data.one.indexOf(val.email) < 0 ? "❌" : "✔"}
        </td>
        <td className="px-4 py-4">
          {data.seventy.indexOf(val.email) < 0 ? "❌" : "✔"}
        </td>
        <td className="px-4 py-4">
          {data.hundred.indexOf(val.email) < 0 ? "❌" : "✔"}
        </td>
      </tr>
      switch (rateSeparator) {
        case "all":
          tr.push(trLine)
          break;
        case "0":
          if (rate === "0" || rate === "all") {
            data.one.indexOf(val.email) < 0 ? tr.push() : tr.push(trLine)
          }
          if (rate === "1" || rate === "all") {
            data.seventy.indexOf(val.email) < 0 ? tr.push() : tr.push(trLine)
          }
          if (rate === "2" || rate === "all") {
            data.hundred.indexOf(val.email) < 0 ? tr.push() : tr.push(trLine)
          }
          break;
        case "1":
          if (rate === "0" || rate === "all") {
            data.one.indexOf(val.email) < 0 ? tr.push(trLine) : tr.push()
          }
          if (rate === "1") {
            data.seventy.indexOf(val.email) < 0 ? tr.push(trLine) : tr.push()
          }
          if (rate === "2") {
            data.hundred.indexOf(val.email) < 0 ? tr.push(trLine) : tr.push()
          }
          break;
        default:
          break;
      }
    })
    setUserList(tr)
  }

  const handlerRate = () => {
    if (rate !== rateRef.current.value) {
      setRate(rateRef.current.value)
    }
    if (rateSeparator !== rateSeparatorRef.current.value) {
      setRateSeparator(rateSeparatorRef.current.value)
    }
  }

  useEffect(() => {
    console.log('handlerUserList', json, totalData)
    handlerUserList()
  }, [json, rate, rateSeparator])

  return (
    <>
      {/* 달성 여부 선택 */}
      <hr className="m-8 my-0" />
      <div className="flex flex-row ml-8">
        <div className="text-left content-start">
          <div className="text-sm w-24 pl-1 font-semibold text-gray-700">달성 여부</div>
          <select name="weeks" id="weeks" className="text-sm w-24 font-semibold text-gray-500" ref={rateSeparatorRef} onChange={() => { handlerRate() }}>
            <option value="all">전체</option>
            <option value="0">달성</option>
            <option value="1">미달성</option>
          </select>
        </div>
        {/* 달성률 선택 */}
        <div className="text-left content-start ml-8">
          <div className="text-sm w-24 pl-1 font-semibold text-gray-700">달성률</div>
          <select name="weeks" id="weeks" className="text-sm w-24 font-semibold text-gray-500" ref={rateRef} onChange={() => { handlerRate() }}>
            <option value="all">전체</option>
            <option value="0">1회 이상</option>
            <option value="1">70% 이상</option>
            <option value="2">100% 완주</option>
          </select>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg m-8 -mt-0">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className=" text-gray-700 bg-gray-50">
            <tr>
              <th scope="col" className="w-1/4 px-4 py-3">
                참여자({userCnt})
              </th>
              <th scope="col" className="w-1/4 px-4 py-3">
                1회 이상({userTotal[0]})
              </th>
              <th scope="col" className="w-1/4 px-4 py-3">
                70% 이상({userTotal[1]})
              </th>
              <th scope="col" className="w-1/4 px-4 py-3">
                100% 완주자({userTotal[2]})
              </th>
            </tr>
          </thead>
          <tbody>
            {userList}
          </tbody>
        </table>
      </div>
    </>
  );
}


export default UserList;