import React from "react"
import CampApply from "../components/Plan";
import Menu from "../components/Menu";
import Plan from "../components/Plan";
import Laboratory from "../components/Laboratory";
import Payment from "../components/Payment";
import Settlement from "../components/Settlement";

const LaboratoryPage = () => {
  return (
    <>
      <div >
        <Settlement />
        <Payment />

        {/* <Laboratory /> */}
      </div>
    </>
  );
}


export default React.memo(LaboratoryPage);