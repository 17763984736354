import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signIn, signInCheck } from '../modules/firebase';

const Login = () => {
  const dispatch = useDispatch();
  const handlerSignIn = () => {
    dispatch(signIn())
  }
  useEffect(()=>{
    console.log('effectSignInCheck')
    
    dispatch(signInCheck())
  },[])
  return (
    <>
      <div className="h-full w-full">
        <div className="flex pt-12 content-center justify-center h-full min-h-screen min-w-screen ">
          <div className="container mx-auto px-4">
            <div className="mx-auto w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-50 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Sign in with
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    <button
                      className="bg-white text-blueGray-700 text-xl  px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 shadow hover:shadow-md inline-flex items-center font-bold ease-linear transition-all duration-150"
                      type="button"
                      onClick={handlerSignIn}
                    >
                      <p className="text-blue-500">G</p>
                      <p className="text-red-500">o</p>
                      <p className="text-yellow-400">o</p>
                      <p className="text-blue-500">g</p>
                      <p className="text-green-500">l</p>
                      <p className="text-red-500">e</p>
                    </button>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                  <div className="text-blueGray-400 text-center mb-3 font-bold">
                    <small>@xingmove</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
