import React from "react"
import Menu from "../components/Menu";
import StatsBoard from "../components/StatsBoard";
const BoardPage = () => {
  return (
    <>
      <div >
        <StatsBoard />
      </div>
    </>
  );
}


export default React.memo(BoardPage);