import React from "react";

const LevelIcon = ({ state, icon, text, onClick }) => {
  return (
    <>
      <div onClick={onClick} className={
        state === 'enabled' ? "flex items-center text-white relative" :
          state === 'keep' ? "flex items-center text-blue-600 relative" :
            state === 'disabled' ? "flex items-center text-gray-500 relative" : ''}>
        <div className={
          state === 'enabled' ? "bg-blue-600 rounded-full transition duration-500 ease-in-out h-12 w-12 justify-center flex items-center border-2 border-blue-600" :
            state === 'keep' ? "rounded-full transition duration-500 ease-in-out h-12 w-12 justify-center flex items-center border-2 border-blue-600" :
              state === 'disabled' ? "rounded-full transition duration-500 ease-in-out h-12 w-12 justify-center flex items-center border-2 border-gray-300" : ''
        }>
          {icon}
        </div>
        <div className={
          state === 'enabled' || state === 'keep' ? "absolute top-0 -ml-10 text-center mt-14 w-32 font-bold uppercase text-blue-600" :
            state === 'disabled' ? "absolute top-0 -ml-10 text-center mt-14 w-32 font-bold uppercase text-gray-600" : ''
        }>
          {text}
        </div>
      </div>
    </>
  );
}

export default LevelIcon;