import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readNameOrEmailVipListStore, updateAttendanceList } from "../modules/firebase";

const AddAttendanceModal = ({ camp, onCancel }) => {
  const dispatch = useDispatch()
  const [selList, setSelList] = useState([])
  const fbState = useSelector(state => state.firebase);

  const inputRef = useRef()

  useEffect(() => {
    setSelList(fbState.attendanceList)
    console.log("fbState.attendanceList", fbState.attendanceList)
  }, [])

  const handlerUpdateCampList = () => {
    dispatch(updateAttendanceList({ camp: camp, attendanceList: selList }))
    console.log(selList)
    onCancel()
  }

  const readNameOrEmail = () => {
    console.log(inputRef.current.value)
    dispatch(readNameOrEmailVipListStore({ name: inputRef.current.value, email: false }))
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      readNameOrEmail(e);
    }
  }

  function handleSetSelList(val) {
    const isChecked = selList.some(item => item.email === val.email && item.camp);

    if (isChecked) {
      setSelList(selList.map(item => {
        if (item.email === val.email) {
          return {
            ...item,
            camp: false
          }
        }
        return item
      }));
    } else {
      let tempData = {
        "generation": val.generation,
        "name": val.name,
        "email": val.email,
        "camp": true
      }
      setSelList([...selList, tempData]);
    }
    console.log("selList", selList)
  }

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">[{camp}]캠프 인원 추가</h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">검색 후 캠프를 설정해 주세요.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 border-b border-gray-300 mb-4" />
            <div className="m-4">
              <span className="text-xs mb-2 text-gray-500 block">이름</span>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" ref={inputRef} id="username" type="text" placeholder="name" onKeyUp={onKeyPress} />
              <div className="flex justify-between my-2">
              </div>
            </div>


            <div className="shadow-md sm:rounded-lg w-full">
              <div className="w-full text-xs text-center text-gray-500 table-fixed">
                <div className=" text-gray-700 bg-gray-50 flex justify-between content-between">
                  <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                    선택
                  </th>
                  <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                    기수
                  </th>
                  <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                    이름
                  </th>
                  <th scope="col" className="w-6/12 px-2 lg:px-4 py-3">
                    이메일
                  </th>
                </div>
                <div>
                  {Array.isArray(fbState.vipList) ?
                    fbState.vipList.map((val, idx) => {
                      // console.log(selList.some(item => item.email === val.email))
                      return (
                        <div className="bg-white border-b flex justify-between content-between" onClick={() => handleSetSelList(val)} >
                          <td className="w-2/12 px-2 lg:px-4 py-4">
                            <input className="w-4 h-4"
                              type="checkbox"
                              checked={selList.length !== 0 ? selList.some(item => item.email === val.email && item.camp) : false}
                            />
                          </td>
                          <td className="w-2/12 px-2 lg:px-4 py-4">
                            {val.generation}기
                          </td>
                          <th className="w-2/12 px-2 lg:px-4 py-4 text-gray-900">
                            {val.name}
                          </th>
                          <td className="w-6/12 px-2 lg:px-4 py-4  overflow-hidden">
                            {val.email}
                          </td>
                        </div>
                      )
                    }) : <></>
                  }
                </div>
              </div>
            </div>
            {/* btn */}
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-end">
              <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handlerUpdateCampList}>업데이트</button>
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={onCancel}>취소</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAttendanceModal;

