import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { readCamp, readPlan, updatePlan, uploadCamp } from "../modules/firebase";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Plan = () => {
  const dispat = useDispatch()
  const fbState = useSelector((state) => state.firebase);
  const [list, setList] = useState(<></>)
  const [plan, setPlan] = useState(Array(100).fill(''))
  const slickRef = useRef(null);

  useEffect(() => {
    if (fbState.plan !== false) {
      console.log('fbState.usePlan', fbState.plan)
      setPlan(fbState.plan)

      for (var i = 1; i <= 16; i++) {
        try {
          window.document.getElementById(i).value = fbState.plan[i]
          console.log('document', window.document.getElementById(i).value)
        } catch { }
      }
      for (var i = 20 + 1; i <= 20 + 16; i++) {
        try {
          window.document.getElementById(i).value = fbState.plan[i]
          console.log('document', window.document.getElementById(i).value)
        } catch { }
      }
      for (var i = 40 + 1; i <= 40 + 16; i++) {
        try {
          window.document.getElementById(i).value = fbState.plan[i]
          console.log('document', window.document.getElementById(i).value)
        } catch { }
      }
    }
  }, [fbState]);

  useEffect(() => {
    dispat(readPlan())
  }, [])

  useEffect(() => {
    console.log("plan", plan)
  }, [plan])

  const onChangePlan = (e) => {
    var tempPlan = [...plan]
    tempPlan[e.target.id] = e.target.value
    console.log("onChangePlan", tempPlan)
    setPlan(tempPlan)
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const previous = () => { slickRef.current.slickPrev() }
  const next = () => { slickRef.current.slickNext() }
  const handlerUpload = () => {
    dispat(updatePlan({ 'plan': plan }))
  }


  return (
    <>
      <div className="grid grid-cols-10">
        <div className="hidden sm:block">
          <div className="flex flex-col self-center items-end justify-center cursor-pointer h-full" onClick={previous}>
            <div className="border-2 rounded-full border-gray-500 p-1 w-fit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-600">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </div>
          </div>
        </div>
        <div className="sm:col-span-8 col-span-10">
          <Slider className="h-full" {...settings} ref={slickRef}>
            {plan[1 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[1 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[1]}</h3>
                </div>
              </div>
            }
            {plan[2 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[2 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[2]}</h3>
                </div>
              </div>
            }
            {plan[3 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[3 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[3]}</h3>
                </div>
              </div>
            }
            {plan[4 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[4 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[4]}</h3>
                </div>
              </div>
            }
            {plan[5 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[5 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[5]}</h3>
                </div>
              </div>
            }
            {plan[6 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[6 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[6]}</h3>
                </div>
              </div>
            }
            {plan[7 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[7 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[7]}</h3>
                </div>
              </div>
            }
            {plan[8 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[8 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[8]}</h3>
                </div>
              </div>
            }
            {plan[9 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[9 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[9]}</h3>
                </div>
              </div>
            }
            {plan[10 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[10 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[10]}</h3>
                </div>
              </div>
            }
            {plan[11 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[11 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[11]}</h3>
                </div>
              </div>
            }
            {plan[12 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[12 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[12]}</h3>
                </div>
              </div>
            }
            {plan[13 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[13 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[13]}</h3>
                </div>
              </div>
            }
            {plan[14 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[14 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[14]}</h3>
                </div>
              </div>
            }
            {plan[15 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[15 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[15]}</h3>
                </div>
              </div>
            }
            {plan[16 + 20] === '' ? null :
              <div className="p-2 h-full">
                <div className="text-gray-500 font-bold text-center text-gray-900whitespace-nowrap flex-none">
                  {plan[16 + 20]}
                </div>
                <div className="h-full pb-4">
                  <h3 className="whitespace-pre-wrap text-left text-gray-800 break-words bg-slate-400/10 rounded-lg p-4 h-full">{plan[16]}</h3>
                </div>
              </div>
            }
          </Slider>
        </div>
        <div className="hidden sm:block">
          <div className="flex flex-col self-center justify-center cursor-pointer h-full" onClick={next}>
            <div className="border-2 rounded-full border-gray-500 p-1 w-fit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-600">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {!fbState.loading ?
        <>
          <div className="relative shadow-md sm:rounded-lg m-4">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className=" text-gray-700 bg-gray-50">
                <tr>
                  <th scope="col" className="w-1/12 text-center px-4 py-3">
                    날짜
                  </th>
                  <th scope="col" className="w-4/12 text-center px-4 py-3">
                    제목
                  </th>
                  <th scope="col" className="w-7/12 text-center px-4 py-3">
                    계획
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className=" none p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2  w-full sm:w-1/12 h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 1} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2  w-full sm:w-4/12 h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 1} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2  w-full sm:w-7/12 h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={1} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 2} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 2} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={2} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 3} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 3} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={3} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 4} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 4} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={4} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 5} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 5} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={5} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 6} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 6} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={6} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 7} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 7} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={7} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 8} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 8} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={8} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 9} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 9} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={9} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 10} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 10} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={10} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 11} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 11} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={11} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 12} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 12} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={12} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 13} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 13} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={13} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 14} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 14} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={14} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 15} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 15} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={15} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td colSpan={3} scope="row" className="p-2 text-center text-gray-500 whitespace-nowrap data">
                    <div className="block sm:flex">
                      <div className="sm:mx-2 w-full sm:w-1/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={40 + 16} className='border w-full h-16' type='date' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-4/12 relative h-auto flex justify-center">
                        <input onChange={onChangePlan} id={20 + 16} className='border w-full h-16 p-1' type='text' />
                      </div>
                      <div className="sm:mx-2 w-full sm:w-7/12 relative h-auto flex justify-center">
                        <textarea onChange={onChangePlan} id={16} className='border w-full h-16 p-1' type='text' />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
          <div className="flex space-x-2 justify-center">
            <button type="button" onClick={handlerUpload} className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">저장</button>
          </div>
          {/* <div className="flex space-x-2 justify-center">
            <button type="button" onClick={null} className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">업데이트</button>
          </div> */}
        </>
        : <></>}
    </>
  );
}


export default React.memo(Plan);