import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { deletePayment, readPayment, } from "../modules/firebase";
import { addOneMonthToDate, toDateFormat } from "../common/common";

const Payment = () => {
  const dispatch = useDispatch()
  const fbState = useSelector(state => state.firebase);

  useEffect(() => {
    dispatch(readPayment())
    console.log("fbState.readPayment", fbState.paymentDataList)
  }, [])

  const handlerDeletePayment = (paymentInfo) => {

    const confirmed = window.confirm("확인하시겠습니까?");

    if (confirmed) {
      dispatch(deletePayment({ paymentInfo: paymentInfo, level: paymentInfo.level }))
      console.log("사용자가 확인을 클릭했습니다.");
    } else {
      // 취소 버튼을 클릭한 경우
      // 원하는 동작 수행
      console.log("사용자가 취소를 클릭했습니다.");
    }

    console.log("handlerDeletePayment", paymentInfo)
  }

  const formatPrice = (price) => {
    return price.toLocaleString('ko-KR');
  };

  return (
    <>
      <div className="px-8 py-2 w-full">
        <div className="shadow-md sm:rounded-lg w-full">
          <table className="w-full text-xs text-center text-gray-500 table-fixed">
            <thead className="text-gray-700 bg-gray-50 ">
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                구분
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                구매일
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                만료일
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                이름
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                난도
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                쿠폰
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                금액
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                할인률
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                판매가
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                비고
              </th>
              <th scope="col" className="w-2/12 px-2 lg:px-4 py-3">
                취소
              </th>
            </thead>
            <tbody className="">
              {Array.isArray(fbState.paymentDataList) && !fbState.loading ?
                fbState.paymentDataList.map((val, idx) => {
                  const date = new Date(val.date.seconds * 1000);
                  return (
                    <tr className={val.state === 1 ? "border-b border-gray-200" : "border-b border-gray-200 bg-red-50"}>
                      <td className="px-2 lg:px-4 py-4 text-gray-900 text-center">
                        {val.state === 1 ? "구매" : val.price > 0 ? "환불" : "차감"}
                      </td>
                      <td className="px-2 lg:px-4 py-4 text-gray-900 text-center">
                        {toDateFormat(date)}
                      </td>
                      <td className="px-2 lg:px-4 py-4 text-gray-900 text-center">
                        {addOneMonthToDate(date)}
                      </td>
                      <td className="text-center text-gray-900">
                        {val.name}
                      </td>
                      <td className="text-center text-gray-900">
                        {val.level === 1 ? "초급" : ""}
                        {val.level === 2 ? "중급" : ""}
                        {val.level === 3 ? "상급" : ""}
                        {val.level === 4 ? "무브먼트" : ""}
                        {val.level === 5 ? "스타터" : ""}
                      </td>
                      <td className="text-center text-gray-900">
                        {val.count}
                      </td>
                      <td className="text-center text-gray-900">
                        {formatPrice(val.price)}
                      </td>
                      <td className="text-center text-gray-900">
                        {val.state === 1 ? val.discount + "%" : "-"}
                      </td>
                      <td className="text-center text-gray-900">
                        {val.state === 1 ? formatPrice(val.discountedPrice) : "-"}
                      </td>
                      <td className="text-center text-gray-900">
                        {val.description}
                      </td>
                      <td className="text-center text-gray-900">
                        <div className="mx-2 py-2 rounded text-gray-800 bg-red-400 font-semibold cursor-pointer active:bg-red-500 transition duration-300 ease"
                          onClick={() => { handlerDeletePayment(val) }}
                        >
                          취소
                        </div>
                      </td>
                    </tr>
                  )
                })
                : <></>
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Payment;
