import React, { useEffect, useState } from "react";
const AttendanceBtn = ({ attendance = 0, onAttendance, onAbsence, onMakeupClass, onReset, state, visible = false }) => {
  attendance = isNaN(attendance) ? 0 : attendance
  const attendanceState = state.indexOf('1') !== -1 ? 11 :
    state.indexOf('100') !== -1 ? 101 :
      state.indexOf('200') !== -1 ? 201 : 0
  const btnState = attendance === 1 || attendance === 100 || attendance === 200 ? attendance : attendanceState
  const [checkBox, setCheckBox] = useState()

  useEffect(() => {
    switch (btnState) {
      case 0:
      case 11:
      case 101:
      case 201:
        setCheckBox(
          <>
            <span onClick={onAttendance} className={`${attendanceState === 11 ? 'bg-green-300 border-2 border-green-500' : 'bg-gray-300 border-2 border-gray-300 text-gray-500'} w-full flex justify-center rounded cursor-pointer active:bg-gray-500 h-6 items-center`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </span>
            <span onClick={onAbsence} className={`${attendanceState === 101 ? 'bg-red-300 border-2 border-red-500' : 'bg-red-100 border-2 border-red-100'} w-full flex justify-center rounded cursor-pointer active:bg-gray-500 h-6 items-center`}>
              결석
            </span>
            <span onClick={onMakeupClass} className={`${attendanceState === 201 ? 'bg-yellow-300 border-2 border-yellow-500' : 'bg-yellow-100 border-2 border-yellow-100'} w-full flex justify-center rounded cursor-pointer active:bg-gray-500 h-6 items-center`}>
              보강
            </span>
          </>
        )
        break;
      case 1:
        setCheckBox(
          <span onClick={onReset} className={`bg-green-400 w-full flex justify-center rounded cursor-pointer active:bg-green-500 h-full items-center`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-5 h-5 text-gray-700">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </span>
        )
        break;

      case 100:
        setCheckBox(
          <span onClick={onReset} className={`bg-red-300 w-full flex justify-center rounded cursor-pointer active:bg-gray-500 h-full items-center`}>
            결석
          </span>
        )
        break;

      case 200:
        setCheckBox(
          <span onClick={onReset} className={`bg-yellow-300 w-full flex justify-center rounded cursor-pointer active:bg-gray-500 h-full items-center`}>
            보강
          </span>
        )
        break;
      default:
        break;
    }
  }, [btnState])

  return (
    <div className="flex flex-col items-center w-full font-semibold h-[4.5rem] text-slate-800 transition duration-300 ease">
      {visible ? btnState : undefined}
      {checkBox}
    </div>
  );
}

export default AttendanceBtn;