import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import VipStatsCard from "../atom/VipStatsCard";
import VipList from "../atom/VipList";
import { downloadVipListData, readCamp, readNameOrEmailVipListStore, readVipListData, updateVipListData, uploadCamp } from "../modules/firebase";
import Papa from "papaparse";
import NewModal from "../atom/NewModal";
import moment from "moment"

const VipMembers = () => {
  const dispatch = useDispatch()
  const fbState = useSelector(state => state.firebase);
  const inputRef = useRef()
  const [level, setLevel] = useState([1, 2, 3])
  const level1 = useRef()
  const level2 = useRef()
  const level3 = useRef()
  const [csvList, setCsvList] = useState([])
  const [modal, setModal] = useState("")

  useEffect(() => {
    // dispatch(readVipListData({ nextPage: true, level: level }))
    level1.current.checked = true
    level2.current.checked = true
    level3.current.checked = true
  }, []);

  useEffect(() => {
    dispatch(readVipListData({ nextPage: true, reset: true, level: level }))
    console.log(level)
  }, [level]);

  useEffect(() => {
    console.log("downloadVipList", fbState.downloadVipList)
    if (fbState.downloadVipList !== undefined) {
      console.log("not undefined")
      var fileName = `${moment(new Date()).format("YYYYMMDD")}-membership`
      var csv = Papa.unparse(fbState.downloadVipList);
      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, fileName);
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', fileName);
      tempLink.click();
    }
  }, [fbState.downloadVipList]);

  const handlerNextPage = () => {
    dispatch(readVipListData({ nextPage: true, level: level }))
  }
  const handlerPrevPage = () => {
    dispatch(readVipListData({ nextPage: false, level: level }))
  }
  const handlerLevel = () => {
    setLevel([
      level1.current.checked === true ? 1 : 'null',
      level2.current.checked === true ? 2 : '',
      level3.current.checked === true ? 3 : ''
    ])
    console.log(level)
  }
  const csvRead = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      dynamicTyping: true,
      encoding: "utf-8",
      skipEmptyLines: true,
      complete: function (results) {
        const res = results.data.map((data) => {
          return {
            "createAt": data.createAt,
            "generation": data.generation,
            "name": data.name,
            "email": data.email,
            "level": data.level,
            "lastAt": data.lastAt,
            "inactiveAt": data.inactiveAt,
            "state": data.state,
            "achievement": {
              "s20221": data.s20221,
              "s20222": data.s20222,
              "s20223": data.s20223,
              "s20224": data.s20224,
              "s20231": data.s20231,
              "s20232": data.s20232,
              "s20233": data.s20233,
              "s20234": data.s20234,
            }
          }
        })
        console.log(res)
        setCsvList(res)
      },
    });
  }

  const csvUpload = () => {
    console.log(csvList)
    dispatch(updateVipListData({ vipList: csvList }))
  }
  const csvDownload = () => {
    console.log("csvList", csvList)

    dispatch(downloadVipListData())

  }
  const readNameOrEmail = () => {
    console.log(inputRef.current.value)
    dispatch(readNameOrEmailVipListStore({ name: inputRef.current.value, email: false }))
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      readNameOrEmail(e);
    }
  }

  const handlerPopupNewModal = () => {
    setModal(<NewModal onCancel={handlerPopupNewModalCancel} />)
  }

  const handlerPopupNewModalCancel = () => {
    setModal("")
  }

  return (
    <>
      {modal}
      <div className="flex flex-col self-center items-center justify-center">
        {/* {전체 활성화 유저수} */}
        {/* <div>
          <div className="px-4 md:px-10 mx-auto w-full">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                <VipStatsCard
                  title="초급자"
                  subTitle={'N명 활성화'}
                />
              </div>
              <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                <VipStatsCard
                  title="중급자"
                  subTitle={'N명 활성화'}
                />
              </div>
              <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                <VipStatsCard
                  title="상급자"
                  subTitle={'N명 활성화'}
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* {유저 검색 선택창} */}
        <div className="m-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
            이름
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" ref={inputRef} id="username" type="text" placeholder="Username" onKeyUp={onKeyPress} />
          <div className="flex justify-between my-2">
            <label className="text-sm font-bold text-gray-700 mx-2 items-center flex">
              <input type="checkbox" className="accent-blue-500 w-4 h-4 mx-1" ref={level1} onChange={handlerLevel} /> 초급자
            </label>
            <label className="text-sm font-bold text-gray-700 mx-2 items-center flex">
              <input type="checkbox" className="accent-blue-500 w-4 h-4 mx-1" ref={level2} onChange={handlerLevel} /> 중급자
            </label>
            <label className="text-sm font-bold text-gray-700 mx-2 items-center flex">
              <input type="checkbox" className="accent-blue-500 w-4 h-4 mx-1" ref={level3} onChange={handlerLevel} /> 상급자
            </label>
          </div>
        </div>
        {/* {유저 리스트} */}
        {!fbState.loading ?
          <VipList list={fbState.vipList} /> : <></>
        }
        {/* 페이지 */}
        <div className="flex w-full flex-row justify-between px-8">
          <button onClick={handlerPopupNewModal} className="inline-flex items-center py-2 px-6 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </button>
          <div>
            <button onClick={handlerPrevPage} className="inline-flex items-center py-2 px-6 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
            </button>
            <button onClick={handlerNextPage} className="inline-flex items-center py-2 px-6 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
          </div>
        </div>

        {/* {CSV 다운로드} */}
        <hr className="w-full m-4" />
        <div className="sm:block ">
          <label className="block center text-gray-700 text-sm font-bold mb-2" >
            멤버 리스트 다운로드(CSV)
          </label>
        </div>
        <div className="flex space-x-2 justify-center">
          <div onClick={csvDownload} className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">다운로드</div>
        </div>

        {/* {CSV 업로드} */}
        <hr className="w-full m-4" />
        <div className="sm:block ">
          <label className="block text-gray-700 text-sm font-bold mb-2" >
            멤버 리스트 업로드(CSV)
          </label>
          <input className="block w-48 text-sm text-gray-500 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" type="file" onChange={csvRead} accept=".csv" />
        </div>
        <br />
        <div className="flex space-x-2 justify-center">
          <button type="button" onClick={csvUpload} className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">업데이트</button>
        </div>
        <hr className="w-full m-4" />
      </div>
    </>
  );
}

export default React.memo(VipMembers);
