import React from "react";
import PropTypes from "prop-types";

const StatsCard = ({
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescripiron,
  statSrc,
  statNone,
}) => {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4  bg-gray-50">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-zinc-500 uppercase font-bold text-xs">
                {statSubtitle}
              </h5>
              <span className="font-semibold text-xl text-zinc-700">
                {statTitle}%
              </span>
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              <div
                className={
                  "p-2 bg-white text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-lightBlue-200"
                }
              >
                <img src={statSrc} alt="icon" />
              </div>
            </div>
          </div>
          {!statNone ?
            <p className="text-sm text-zinc-500 mt-4">
              <span className={statPercentColor}>
                {statPercent}%
              </span>
              <span className={statPercentColor + " mr-2"}>
                {statArrow === "up" ?
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-flex" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                  </svg>
                  : <></>}
                {statArrow === "down" ?
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-flex" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                  : <></>}
                {statArrow !== "down" && statArrow !== "up" ?
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-flex" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  : <></>}
                {" "}
              </span>
              <span className="whitespace-nowrap">{statDescripiron}</span>
            </p> : <></>}
        </div>
      </div>
    </>
  );
}

export default StatsCard;