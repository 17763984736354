import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { deleteAttendance, readAttendanceList, readCouponsList, recentPayment, setAttendance, setDescription, updateCoupon } from "../modules/firebase";
import AttendanceBtn from "../atom/AttendanceBtn";
import AddAttendanceModal from "../atom/AddAttendanceModal";
import PaymentModal from "../atom/PaymentModal";
import MemberModal from "../atom/MemberModal";
import { addOneMonthToDate, toDateFormat } from "../common/common";

const Attendance = () => {
  const dispatch = useDispatch()
  const fbState = useSelector(state => state.firebase);
  const [selectedCamp, setSelectedCamp] = useState(0);
  const [modal, setModal] = useState("")
  const [memberModal, setMemberModal] = useState("")

  const today = new Date()
  const [selectedMonth, setSelectedMonth] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' : ''}${new Date().getMonth() + 1}`);
  const [numDaysInMonth, setNumDaysInMonth] = useState(1)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleDate, setIsVisibleDate] = useState(JSON.parse(localStorage.getItem('isVisibleDate')));

  const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
  const tabs = [
    { label: '화요일 3시', level: 1 },
    { label: '화요일 8시', level: 1 },
    { label: '목요일 3시', level: 1 },
    { label: '목요일 8시', level: 1 },
    { label: '일요일 12시', level: 1 },
    { label: '일요일 3시', level: 1 },
    { label: '수요일 8시', level: 2 },
    { label: '토요일 3시', level: 2 },
    { label: '월요일 3시', level: 4 },
    { label: '월요일 8시', level: 4 },
    { label: '토요일 12시', level: 4 },
    { label: '목요일 6시', level: 5 },
    { label: '금요일 8시', level: 5 },
    { label: '토요일 10시', level: 5 },
  ];

  const attendanceDataModel = (val, date) => {
    const { generation, name, email } = val;
    const camp = tabs[selectedCamp].label;
    const level = tabs[selectedCamp].level;

    const attendanceData = {
      generation,
      name,
      email,
      camp,
      date,
      level
    };

    return attendanceData
  }

  useEffect(() => {
    dispatch(readCouponsList({ level: tabs[selectedCamp].level }))
    calendarState()
    console.log("localStorage.setItem('isVisibleDate', e.target.checked)", localStorage.getItem('isVisibleDate'), isVisibleDate)
  }, [])

  useEffect(() => {
    calendarState()
  }, [selectedMonth])

  useEffect(() => {
    dispatch(recentPayment({ email: "jyd6670@naver.com" }))

    dispatch(readCouponsList({ level: tabs[selectedCamp].level }))
    dispatch(readAttendanceList({ camp: tabs[selectedCamp].label, year: year, month: month, level: tabs[selectedCamp].level }))
  }, [selectedCamp]);

  const calendarState = () => {
    const [year, month] = selectedMonth.split('-');
    console.log("calendarState", year, month)
    setYear(parseInt(year))
    setMonth(parseInt(month))
    setNumDaysInMonth(new Date(parseInt(year), parseInt(month), 0).getDate())
    dispatch(readAttendanceList({ camp: tabs[selectedCamp].label, year: year, month: month, level: tabs[selectedCamp].level }))
  }

  const someAsyncFunction = async (val, date, state) => {
    return new Promise((resolve, reject) => {
      // 비동기 작업을 수행하는 코드
      let attendanceData = attendanceDataModel(val, date)
      attendanceData.state = Number(state)
      dispatch(setAttendance({ attendanceData: attendanceData, level: tabs[selectedCamp].level, state: state }))
      console.log("handlerAttendance", attendanceData)
      // 예시로 setTimeout 함수를 사용하여 1초 후에 완료되는 비동기 작업을 가정합니다.
      setTimeout(() => {
        resolve('Async task completed successfully.');
      }, 1000);
    });
  }

  const handlerAttendance = (val, date, state) => {
    if (isLoading) {
      return; // 이미 호출 중인 경우에는 더 이상 처리하지 않음
    }
    setIsLoading(true); // 호출 시작 시 로딩 상태 변경
    someAsyncFunction(val, date, state)
      .finally(() => {
        setIsLoading(false); // 호출 완료 시 로딩 상태 변경
      });
  }

  const handlerDescription = (val, date, description) => {
    let attendanceData = attendanceDataModel(val, date)
    attendanceData.description = description
    dispatch(setDescription({ attendanceData: attendanceData, level: tabs[selectedCamp].level }))
    console.log("handlerAttendance", attendanceData)
  }

  const handlerAttendanceReset = (val, date) => {
    let attendanceData = attendanceDataModel(val, date)
    attendanceData.state = 0
    dispatch(deleteAttendance({ attendanceData: attendanceData, level: tabs[selectedCamp].level }))
    console.log("handlerAttendanceReset", attendanceData)
  }

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleChangeTab = (index) => {
    setSelectedCamp(index);
  }

  const handlerPopupAddAttendanceModal = () => {
    setModal(<AddAttendanceModal camp={tabs[selectedCamp].label} onCancel={handlerPopdownModalCancel} />)
  }

  const handlerPopupPaymentModal = (val) => {
    console.log('val', val)
    setModal(<PaymentModal data={val} onCancel={handlerPopdownModalCancel} isLevel={tabs[selectedCamp].level} />)
  }

  const handlerPopdownModalCancel = () => {
    setModal("")
  };

  const handlerPopupMemberModal = (val, logs, count) => {
    console.log('val', val)
    setModal(<MemberModal data={val} logs={logs} onCancel={handlerPopdownMeberModalCancel} isLevel={tabs[selectedCamp].level} count={count} />)
  }

  const handlerPopdownMeberModalCancel = () => {
    setModal("")
  };

  const handlerIncrement = (email, count, level) => {
    console.log(email, count, level)
    dispatch(updateCoupon({ email: email, count: count, level: level }))
  }

  const handlerDecrement = (email, count, level) => {
    console.log(email, count, level)
    dispatch(updateCoupon({ email: email, count: count, level: level }))
  }
  const getLevelLabel = (level) => {
    switch (level) {
      case 1:
        return '초급';
      case 2:
        return '중급';
      case 3:
        return '상급';
      case 4:
        return '무브먼트';
      case 5:
        return '스타터';
      default:
        return '정보없음';
    }
  }
  const debuggerTest = () => {
    debugger;
  }
  return (
    <>
      {modal}
      {memberModal}

      <div className="p-4">
        <div className="flex flex-row my-2">
          {/* <div className="flex text-gray-700 font-semibold">
            <div>
              미출석
            </div>
            <span className="p-1 rounded-full text-gray-800 bg-gray-300 font-semibold text-sm cursor-pointer active:bg-gray-500 transition duration-300 ease text-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-4 h-4 text-gray-300">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </span>
          </div>
          <div className="flex text-gray-700 font-semibold">
            출석
            <span className="p-1 rounded-full text-gray-800 bg-green-400 font-semibold text-sm cursor-pointer active:bg-green-500 transition duration-300 ease text-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-4 h-4 text-gray-700">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </span>
          </div> */}

          <div className="flex text-gray-700 font-semibold">
            상태코드
            <input type="checkbox" onClick={(e) => {
              console.log(e.target.checked)
              setIsVisible(e.target.checked)
            }} />
          </div>
          <div className="m-2"></div>
          <div className="flex text-gray-700 font-semibold">
            전체요일
            <input type="checkbox" checked={isVisibleDate} onClick={(e) => {
              console.log(e.target.checked)
              localStorage.setItem('isVisibleDate', JSON.stringify(e.target.checked));
              setIsVisibleDate(e.target.checked)
            }} />
          </div>

        </div>

        <div className="flex flex-col text-left items-start text-sm font-medium text-gray-500 border-b border-gray-200">
          <div>
            <input
              className="rounded border px-4 py-2 w-[14rem] font-semibold text-black"
              type="month"
              value={selectedMonth}
              onChange={handleMonthChange}
            />
          </div>
          <div>
            <button className={`inline-block p-2 w-28 rounded border px-4 py-2 font-semibold  border-gray-400`}>
              스타터
            </button>
            {tabs.slice(11, 14).map((tab, index) => {
              index += 11
              return (
                <button
                  key={index}
                  className={`inline-block p-2 w-28 rounded border px-4 py-2 font-semibold bg-green-100
              ${index === selectedCamp ? 'text-blue-700 shadow-inner outline outline-2 outline-offset-[-2px] border-blue-700' : 'text-gray-700 border-gray-400'}`}
                  onClick={() => handleChangeTab(index)}
                >
                  {tab.label}
                </button>
              )
            })}
          </div>
          <div>
            <button className={`inline-block p-2 w-28 rounded border px-4 py-2 font-semibold  border-gray-400`}>
              초급
            </button>
            {tabs.slice(0, 6).map((tab, index) => (
              <button
                key={index}
                className={`inline-block p-2 w-28 rounded border px-4 py-2 font-semibold bg-blue-200
              ${index === selectedCamp ? 'text-blue-700 shadow-inner outline outline-2 outline-offset-[-2px] border-blue-700' : 'text-gray-700 border-gray-400'}`}
                onClick={() => handleChangeTab(index)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div>
            <button className={`inline-block p-2 w-28 rounded border px-4 py-2 font-semibold  border-gray-400`}>
              중급
            </button>
            {tabs.slice(6, 8).map((tab, index) => {
              index += 6
              return (
                <button
                  key={index}
                  className={`inline-block p-2 w-28 rounded border px-4 py-2 font-semibold bg-red-200
              ${index === selectedCamp ? 'text-blue-700 shadow-inner outline outline-2 outline-offset-[-2px] border-blue-700' : 'text-gray-700 border-gray-400'}`}
                  onClick={() => handleChangeTab(index)}
                >
                  {tab.label}
                </button>
              )
            })}
          </div>
          <div>
            <button className={`inline-block p-2 w-28 rounded border px-4 py-2 font-semibold  border-gray-400`}>
              무브먼트
            </button>
            {tabs.slice(8, 11).map((tab, index) => {
              index += 8
              return (
                <button
                  key={index}
                  className={`inline-block p-2 w-28 rounded border px-4 py-2 font-semibold bg-amber-100 bg
                ${index === selectedCamp ? 'text-blue-700 shadow-inner outline outline-2 outline-offset-[-2px] border-blue-700' : 'text-gray-700 border-gray-400'}`}
                  onClick={() => handleChangeTab(index)}
                >
                  {tab.label}
                </button>
              )
            })}
          </div>
        </div>
        <div>
          <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full table-fixed text-xs text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase border-b">
                <tr>
                  <th scope="col" className="p-3 w-16 text-center bg-white sticky left-0 ">
                    #
                  </th>
                  <th scope="col" className="p-3 w-[160px] text-center bg-gray-100 sticky left-[3.9rem] border-r">
                    멤버정보
                  </th>
                  {Array.from({ length: numDaysInMonth }).map((_, index) => {
                    const dayOfMonth = index + 1;
                    const isOdd = dayOfMonth % 2 !== 0;
                    const isToday = dayOfMonth === today.getDate() && today.getMonth() + 1 === month
                    const dayOfWeekIndex = new Date(year, month - 1, dayOfMonth).getDay();
                    const dayOfWeek = daysOfWeek[dayOfWeekIndex];


                    if (dayOfWeek !== tabs[selectedCamp].label.slice(0, 1) && !isVisibleDate) {
                      return null;
                    }

                    return (
                      <th className={`p-3 ${!isVisibleDate ? '' : 'w-16'} text-center ${isOdd ? '' : 'bg-gray-100'} ${isToday ? 'bg-yellow-300/50 border-t-[3px] border-x-[3px] border-yellow-500' : ''}`}
                        id={`dayOfMonth${dayOfMonth}`}
                        key={dayOfMonth}>
                        {dayOfMonth}[{dayOfWeek}]
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(fbState.attendanceList) && !fbState.loading ?
                  fbState.attendanceList.map((val, idx) => {
                    var isAttendance = Array(numDaysInMonth).fill("/")
                    var isDescription = Array(numDaysInMonth).fill("")
                    var data = []
                    var paymentLog = false
                    var paymentLogs = false
                    var paymenLogDate = ""
                    var expirationDate = ""
                    try {
                      paymentLogs = fbState.memberPaymentDataList.find((item) => item.email === val.email)
                      paymentLogs.data.forEach((item) => {
                        if (item.level === tabs[selectedCamp].level) {
                          paymentLog = item
                          console.log("logs", item, item.level === tabs[selectedCamp].level, item.level, tabs[selectedCamp].level)
                          return;
                        }
                      })

                      paymenLogDate = toDateFormat(new Date(paymentLog.date.seconds * 1000))
                      expirationDate = addOneMonthToDate(new Date(paymentLog.date.seconds * 1000))
                    } catch (error) {
                      paymentLog = false
                      paymenLogDate = "결제정보 없음"
                    }

                    try {
                      expirationDate = addOneMonthToDate(new Date(paymentLog.expirationDate.seconds * 1000))
                    } catch (error) {
                      expirationDate += "(미지정)"
                    }

                    console.log("paymentLog", paymentLog)
                    if (Array.isArray(fbState.attendanceData) && fbState.attendanceData.length > 0) {
                      isAttendance = Array(numDaysInMonth).fill("/")
                      try {
                        data = fbState.attendanceData.find(item => item.email === val.email).data
                        data.forEach(item => {
                          const date = new Date(item.date.seconds * 1000);
                          const dayOfFirebase = date.getDate();
                          const state = item.state
                          const camp = item.camp
                          isAttendance[dayOfFirebase - 1] += `${state}/${camp}/`
                          isDescription[dayOfFirebase - 1] = item?.description || ""
                        })
                        console.log("isAttendance", fbState.attendanceData.find(item => item.email === val.email).data, "isAttendance", isAttendance, val.email)
                      } catch (error) { }
                    }


                    return (
                      <tr className="border-b border-2 border-slate-400" key={idx}>
                        <th className="px-2 lg:px-4 py-4  w-[160px] text-gray-900 text-center bg-white sticky left-0 border">
                          {idx + 1}
                        </th>
                        <th className="text-center text-gray-900  w-[160px] bg-gray-100 sticky left-[3.9rem] border-r leading-4">
                          <div className="border-b flex items-center justify-center">
                            {val.generation}기 {val.name}
                          </div>
                          <div className="border-b flex items-center justify-center">
                            잔여 : {
                              typeof fbState.couponsList.find((item) => item.email === val.email) !== 'undefined' ?
                                <span className={`${fbState.couponsList.find((item) => item.email === val.email).count < 0 ? "text-red-600 underline decoration-2 decoration-red-500" : "text-gray-900"}`}>
                                  {fbState.couponsList.find((item) => item.email === val.email).count}
                                </span>
                                : 0
                            }
                            <button onClick={() => { handlerIncrement(val.email, 1, tabs[selectedCamp].level) }} className="rounded border font-semibold border-gray-400 h-4 w-4 hover:bg-sky-200">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                              </svg>
                            </button>
                            <button onClick={() => { handlerDecrement(val.email, -1, tabs[selectedCamp].level) }} className="rounded border font-semibold border-gray-400 h-4 w-4 hover:bg-red-200">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                              </svg>
                            </button>
                          </div>
                          <div className="border-b flex items-center justify-center">
                            <span className="m-0 p-0">
                              [{
                                val.camp.map((item, idx) => {
                                  const thisLevel = tabs.some(tab => { return tab.label === item && tab.level === tabs[selectedCamp].level })
                                  return thisLevel ? item.slice(0, 1) : undefined
                                }).sort((a, b) => {
                                  const order = ['월', '화', '수', '목', '금', '토', '일'];
                                  return order.indexOf(a) - order.indexOf(b);
                                }).filter((element) => element !== undefined).join(',')
                              }]
                            </span>
                          </div>
                          <div className={`border-b flex items-center justify-center text-left ${new Date(expirationDate) <= today ? "bg-red-400" : ""}`}>
                            구매일 : {paymenLogDate}
                            <br />
                            만료일 : {expirationDate}
                            <br />
                            쿠폰 : {paymentLog !== false ? paymentLog.count : ""} {paymentLog !== false ? "(" + getLevelLabel(paymentLog.level) + ")" : ""}
                          </div>
                          <div className="border-b flex items-center justify-center h-6 rounded cursor-pointer"
                            style={
                              typeof fbState.couponsList.find((item) => item.email === val.email) !== 'undefined' ?
                                fbState.couponsList.find((item) => item.email === val.email).count > 0 ?
                                  { backgroundColor: "rgb(156 163 175)" } :
                                  { backgroundColor: "rgb(56 189 248)" } :
                                { backgroundColor: "rgb(56 189 248)" }
                            }
                            onClick={() => handlerPopupPaymentModal(val)} >
                            쿠폰관리
                          </div>
                          <div className="border-b flex items-center justify-center h-6 rounded cursor-pointer bg-red-300"
                            onClick={() => handlerPopupMemberModal(val, paymentLogs, typeof fbState.couponsList.find((item) => item.email === val.email) !== 'undefined' ?
                              fbState.couponsList.find((item) => item.email === val.email).count : 0)} >
                            정보
                          </div>
                        </th>
                        {isAttendance.map((item, index) => {
                          const dayOfMonth = index + 1;
                          const isOdd = dayOfMonth % 2 !== 0;
                          const isToday = dayOfMonth === today.getDate() && today.getMonth() + 1 === month
                          const itemArr = item.split("/")
                          const campIndex = itemArr.indexOf(tabs[selectedCamp].label);
                          const dayOfWeekIndex = new Date(year, month - 1, dayOfMonth).getDay();
                          const dayOfWeek = daysOfWeek[dayOfWeekIndex];

                          if (dayOfWeek !== tabs[selectedCamp].label.slice(0, 1) && !isVisibleDate) {
                            return null;
                          }

                          return (
                            <td
                              className={`align-top h-[5.5rem] ${isOdd ? '' : 'bg-gray-100'} ${isToday ? 'bg-yellow-300/50 border-x-[3px] border-yellow-500' : ''}`}
                              key={`${val.email}-${index}`}
                            >
                              <div className="flex flex-col items-center">
                                <AttendanceBtn
                                  visible={isVisible}
                                  state={itemArr}
                                  attendance={Number(itemArr[campIndex - 1])}
                                  onAttendance={() => { handlerAttendance(val, new Date(parseInt(year), parseInt(month - 1), dayOfMonth), 1) }}
                                  onAbsence={() => { handlerAttendance(val, new Date(parseInt(year), parseInt(month - 1), dayOfMonth), 100) }}
                                  onMakeupClass={() => { handlerAttendance(val, new Date(parseInt(year), parseInt(month - 1), dayOfMonth), 200) }}
                                  onReset={() => { handlerAttendanceReset(val, new Date(parseInt(year), parseInt(month - 1), dayOfMonth)); }}
                                />
                                <hr className="w-full border-0 border-b" />
                                <textarea type="TextArea" className="w-full h-16 resize-none border" defaultValue={isDescription[index]}
                                  onChange={(e) => { handlerDescription(val, new Date(parseInt(year), parseInt(month - 1), dayOfMonth), e.target.value) }} />
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    )
                  }) : <></>
                }
              </tbody>
            </table>
          </div>
        </div>

        <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 hover:text-white"
          onClick={handlerPopupAddAttendanceModal} >
          추가
        </button>
      </div>
    </>
  );
}

export default Attendance;