

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { firebaseRTK } from './firebase';

const rootReducer = configureStore(
  {
    reducer: {
      firebase: firebaseRTK.reducer,
    },
  });

export default rootReducer;