import React from "react"
import CampApply from "../components/Plan";
import Menu from "../components/Menu";
import Plan from "../components/Plan";

const PlanPage = () => {
  return (
    <>
      <div >
        <Plan />
      </div>
    </>
  );
}


export default React.memo(PlanPage);