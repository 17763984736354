import { getStorage, ref, uploadBytes } from "firebase/storage";

class FirebaseStorage {
  constructor(app) {
    this.storage = getStorage(app, "gs://xingmove-2dce5.appspot.com");
  }

  async uploadStorage(file) {

    console.log('file');
    console.log(file);
    const jsonRef = ref(this.storage, 'camp.json');
    uploadBytes(jsonRef, file).then((snapshot) => {
      console.log('Uploaded a blob or file!');
    });
  }
}

export default FirebaseStorage;
