import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setVipData, updateVipData } from "../modules/firebase";
import LevelIcon from "./LevelIcon";
import LevelLine from "./LevelLine";

const NewModal = ({ onCancel }) => {
  const dispatch = useDispatch()
  const [level, setLevel] = useState(1)
  const [active, setActive] = useState("엑티브")

  const generationRef = useRef()
  const nameRef = useRef()
  const emailRef = useRef()

  const [alert, setAlert] = useState("fixed z-10 inset-0 overflow-y-auto hidden")

  const handlerSet = () => {
    const date = new Date()
    const newVip = {
      "createAt": date.getFullYear() + '년 ' + date.getMonth() + '월',
      "generation": generationRef.current.value,
      "name": nameRef.current.value,
      "email": emailRef.current.value,
      "level": level,
      "lastAt": null,
      "inactiveAt": null,
      "state": active,
      "achievement": {
        "s20221": null,
        "s20222": null,
        "s20223": null,
      }
    }
    console.log(newVip)
    dispatch(setVipData({ vip: newVip }))
    handlerCancelAlert()
    onCancel()
  }
  const handlerPopupAlert = () => {
    setAlert("fixed z-10 inset-0 overflow-y-auto")
  }
  const handlerCancelAlert = () => {
    setAlert("fixed z-10 inset-0 overflow-y-auto hidden")
  }

  const settingIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
    <g>
      <path d="M17.41,6.59L15,5.5l2.41-1.09L18.5,2l1.09,2.41L22,5.5l-2.41,1.09L18.5,9L17.41,6.59z M21.28,12.72L20.5,11l-0.78,1.72 L18,13.5l1.72,0.78L20.5,16l0.78-1.72L23,13.5L21.28,12.72z M16.24,14.37l1.94,1.47l-2.5,4.33l-2.24-0.94 c-0.2,0.13-0.42,0.26-0.64,0.37L12.5,22h-5l-0.3-2.41c-0.22-0.11-0.43-0.23-0.64-0.37l-2.24,0.94l-2.5-4.33l1.94-1.47 C3.75,14.25,3.75,14.12,3.75,14s0-0.25,0.01-0.37l-1.94-1.47l2.5-4.33l2.24,0.94c0.2-0.13,0.42-0.26,0.64-0.37L7.5,6h5l0.3,2.41 c0.22,0.11,0.43,0.23,0.64,0.37l2.24-0.94l2.5,4.33l-1.94,1.47c0.01,0.12,0.01,0.24,0.01,0.37S16.25,14.25,16.24,14.37z M13,14 c0-1.66-1.34-3-3-3s-3,1.34-3,3s1.34,3,3,3S13,15.66,13,14z" />
    </g>
  </svg>
  const rocketIcon =
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
      <g>
        <path d="M12,2.5c0,0,4.5,2.04,4.5,10.5c0,2.49-1.04,5.57-1.6,7H9.1c-0.56-1.43-1.6-4.51-1.6-7C7.5,4.54,12,2.5,12,2.5z M14,11 c0-1.1-0.9-2-2-2s-2,0.9-2,2s0.9,2,2,2S14,12.1,14,11z M7.69,20.52c-0.48-1.23-1.52-4.17-1.67-6.87l-1.13,0.75 C4.33,14.78,4,15.4,4,16.07V22L7.69,20.52z M20,22v-5.93c0-0.67-0.33-1.29-0.89-1.66l-1.13-0.75c-0.15,2.69-1.2,5.64-1.67,6.87 L20,22z" />
      </g>
    </svg>
  const launchIcon =
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
      <g>
        <g>
          <path d="M9.19,6.35c-2.04,2.29-3.44,5.58-3.57,5.89L2,10.69l4.05-4.05c0.47-0.47,1.15-0.68,1.81-0.55L9.19,6.35L9.19,6.35z M11.17,17c0,0,3.74-1.55,5.89-3.7c5.4-5.4,4.5-9.62,4.21-10.57c-0.95-0.3-5.17-1.19-10.57,4.21C8.55,9.09,7,12.83,7,12.83 L11.17,17z M17.65,14.81c-2.29,2.04-5.58,3.44-5.89,3.57L13.31,22l4.05-4.05c0.47-0.47,0.68-1.15,0.55-1.81L17.65,14.81 L17.65,14.81z M9,18c0,0.83-0.34,1.58-0.88,2.12C6.94,21.3,2,22,2,22s0.7-4.94,1.88-6.12C4.42,15.34,5.17,15,6,15 C7.66,15,9,16.34,9,18z M13,9c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S13,10.1,13,9z" />
        </g>
      </g>
    </svg>

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">신규 유저 추가</h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">기수, 이름, 이메일, 엑티브, 레벨을 설정하시고 추가를 눌러주세요.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4">
              <div className="px-3 border-b border-gray-300 mb-4" />
              <div className="flex self-center justify-center">
                {active == "엑티브" ?
                  <div className="px-3 py-1 text-center w-32 rounded-full text-gray-50 bg-green-500 cursor-pointer active:bg-gray-300 transition duration-300 ease"
                    onClick={() => setActive("휴면")}>
                    ACTIVE
                  </div>
                  :
                  <div className="px-3 py-1 text-center w-32 rounded-full text-gray-50 bg-rose-500 cursor-pointer active:bg-gray-300 transition duration-300 ease"
                    onClick={() => setActive("엑티브")}>
                    INACTIVE
                  </div>
                }
              </div>
              <div>
                <div className="px-3 border-b border-gray-300 my-4" />
                <span className="text-xs mb-2 text-gray-500 block">기수</span>
                <div className="mb-4">
                  <input ref={generationRef} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" placeholder="기수" />
                </div>
                <span className="text-xs mb-2 text-gray-500 block">이름</span>
                <div className="mb-4">
                  <input ref={nameRef} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="이름" />
                </div>
                <span className="text-xs mb-2 text-gray-500 block">이메일</span>
                <div className="mb-4">
                  <input ref={emailRef} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="이메일" />
                </div>
                <button type="button" className="inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out">
                  Level
                </button>

                <div className="py-6">
                  <div className="flex items-center">
                    <LevelIcon state={level === 1 ? 'enabled' : 'keep'} icon={settingIcon} text={'초급자'} onClick={() => setLevel(1)} />
                    <LevelLine disabled={!(level >= 2)} />
                    <LevelIcon state={level === 1 ? 'disabled' : level === 2 ? 'enabled' : 'keep'} icon={rocketIcon} text={'중급자'} onClick={() => setLevel(2)} />
                    <LevelLine disabled={!(level === 3)} />
                    <LevelIcon state={level === 3 ? 'enabled' : 'disabled'} icon={launchIcon} text={'상급자'} onClick={() => setLevel(3)} />
                  </div>
                </div>
                <div className="py-6"></div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-end">
              <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handlerPopupAlert}>추가</button>
              <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={onCancel}>취소</button>
            </div>
          </div>
        </div>
      </div>

      <div className={alert}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>
        <div className="flex items-end sm:items-center justify-center min-h-full text-center sm:p-0 ">
          <div className="relative p-16  rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-blue-100 border-t-4 border-blue-500 rounded text-blue-900 px-4 py-3 shadow-md" role="alert">
              <div className="flex">
                <div className="py-1"><svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                <div>
                  <p className="font-bold">확인</p>
                  <p className="text-sm">해당 내용을 적용 할려면 확인을 클릭하세요.</p>
                </div>

              </div>
              <div className="flex justify-center mt-8">
                <button type="button" className="w-full inline-flex justify-center rounded-md shadow-md border-0 px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handlerSet}>확인</button>
                <button type="button" className="w-full inline-flex justify-center rounded-md shadow-md border-0 px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={handlerCancelAlert}>취소</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default NewModal;