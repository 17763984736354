/* eslint-disable no-redeclare */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"
import Chart from 'chart.js/auto';
import { readData, readUser, resetUserData } from "../modules/firebase";
import StatsCard from "../atom/StatsCard";
import ImgOne from "../icon/3.png"
import ImgTwo from "../icon/2.png"
import ImgThree from "../icon/1.png"
import UserList from "../atom/UserList";
import Papa from "papaparse";
import axios from 'axios';
import { seasonChartCfg, thisChartCfg } from "../assets/config/chartCofig";

const StatsBoard = () => {
  const dispatch = useDispatch()
  const fbState = useSelector((state) => state.firebase);

  const seasonList = {
    '2022s1': [20, 21, 22, 23, 24, 25, 26, 27],
    '2022s2': [35, 36, 37, 38, 39, 40, 41, 42],
    '2022s3': [50, 51, 52, 1, 2, 3, 4, 5],
    '2023s1': [202312, 202313, 202314, 202315, 202316, 202317, 202318, 202319],
    '2023s2': [202326, 202327, 202328, 202329, 202330, 202331, 202332, 202333],
    '2023s3': [202341, 202342, 202343, 202344, 202345, 202346, 202347, 202348],
    '2024s1': [202404, 202405, 202406, 202407, 202408, 202409, 202410, 202411],
  }
  const [season, setSeason] = useState('2024s1')
  const [json, setJson] = useState(false)
  const [jsonUserCnt, setJsonUserCnt] = useState(94)
  const [week, setWeek] = useState(7) //moment(new Date()).isoWeeks()

  const [thisChartCtx, setThisChartCtx] = useState(false)
  const [seasonChartCtx, setSeasonChartCtx] = useState(false)
  const [chartThisData, setThisChartData] = useState()
  const [chartSeasonData, setSeasonChartData] = useState()

  const [one, setOne] = useState(0)
  const [seventy, setSeventy] = useState(0)
  const [hundred, setHundred] = useState(0)

  const [onePre, setOnePre] = useState(0)
  const [seventyPre, setSeventyPre] = useState(0)
  const [hundredPre, setHundredPre] = useState(0)

  const [oneAll, setOneAll] = useState(0)
  const [seventyAll, setSeventyAll] = useState(0)
  const [hundredAll, setHundredAll] = useState(0)
  const [downloadLink, setDownloadLink] = useState('')

  const [downloadButtonkHidden, setDownloadButtonkHidden] = useState('')
  const [downloadLinkHidden, setDownloadLinkHidden] = useState('hidden')

  //Init User Data Read
  useEffect(() => {
    console.log('season', season)
    dispatch(readData())
  }, []);

  //Init User Data Mapping
  useEffect(() => {
    // if (fbState.userData.length === 71) {
    console.log('fbState.userData', fbState.userData.length)
    var data = []
    fbState.userData.map((value) => {
      var tempList = []
      tempList.push(value['name'])
      tempList.push(value['email'])
      for (var i = 0; i <= 7; i++) {
        try {
          tempList.push(value[seasonList[season][i]])
        } catch (e) { tempList.push(0) }
      }
      data.push(tempList)
    })
    console.log('dataList', data)

    const fields = ['name', 'email', '1주', '2주', '3주', '4주', '5주', '6주', '7주', '8주'];
    const csv = Papa.unparse({
      data,
      fields
    });
    const blob = new Blob([csv]);
    const download = URL.createObjectURL(blob, { type: 'text/plain' })
    setDownloadLink(download)

    console.log(csv)
    // }
  }, [fbState.userData])

  //Init User Data Set Chart
  useEffect(() => {
    if (!fbState.loading) {
      try {
        setThisChartCtx(new Chart(document.getElementById('thisChart').getContext('2d'), thisChartCfg))
        changeChartData(seasonList['2023s1'])
        setSeasonChartCtx(new Chart(document.getElementById('seasonChart').getContext('2d'), seasonChartCfg))
      } catch (error) { }
    }
  }, [fbState]);

  useEffect(() => {
    if (!fbState.loading) {
      try {
        thisChartCtx.config.data.datasets = chartThisData
        thisChartCtx.update()

        seasonChartCtx.config.data.datasets = [
          {
            label: '2022여름',
            backgroundColor: "#a78bfa",
            borderColor: "#a78bfa",
            data: [
              Math.floor(seasonList['2022s1'].map(week => Math.floor((fbState.totalData[week].one.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2022s1'].map(week => Math.floor((fbState.totalData[week].seventy.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2022s1'].map(week => Math.floor((fbState.totalData[week].hundred.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
            ],
            fill: false,
          },
          {
            label: '2022가을',
            backgroundColor: "#5eead4",
            borderColor: "#5eead4",
            data: [
              Math.floor(seasonList['2022s2'].map(week => Math.floor((fbState.totalData[week].one.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2022s2'].map(week => Math.floor((fbState.totalData[week].seventy.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2022s2'].map(week => Math.floor((fbState.totalData[week].hundred.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
            ],
          },
          {
            label: '2022겨울',
            backgroundColor: "#f43f5e",
            borderColor: "#f43f5e",
            data: [
              Math.floor(seasonList['2022s3'].map(week => Math.floor((fbState.totalData[week].one.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2022s3'].map(week => Math.floor((fbState.totalData[week].seventy.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2022s3'].map(week => Math.floor((fbState.totalData[week].hundred.length / jsonUserCnt) * 100)).reduce((acc, val) => acc + val, 0) / 8),
            ],
          },
          {
            label: '2023봄',
            backgroundColor: "#4ed43f",
            borderColor: "#4ed43f",
            data: [
              Math.floor(seasonList['2023s1'].map(season => {
                const oneLength = fbState.totalData[season]?.one?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2023s1'].map(season => {
                const oneLength = fbState.totalData[season]?.seventy?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2023s1'].map(season => {
                const oneLength = fbState.totalData[season]?.hundred?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
            ],
          },
          {
            label: '2023여름',
            backgroundColor: "#ffbd2b",
            borderColor: "#ffbd2b",
            data: [
              Math.floor(seasonList['2023s2'].map(season => {
                const oneLength = fbState.totalData[season]?.one?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2023s2'].map(season => {
                const oneLength = fbState.totalData[season]?.seventy?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2023s2'].map(season => {
                const oneLength = fbState.totalData[season]?.hundred?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
            ],
          },
          {
            label: '2023가을',
            backgroundColor: "#affd2b",
            borderColor: "#affd2b",
            data: [
              Math.floor(seasonList['2023s3'].map(season => {
                const oneLength = fbState.totalData[season]?.one?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2023s3'].map(season => {
                const oneLength = fbState.totalData[season]?.seventy?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2023s3'].map(season => {
                const oneLength = fbState.totalData[season]?.hundred?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
            ],
          },
          {
            label: '2024겨울',
            backgroundColor: "#8fadab",
            borderColor: "#8fadab",
            data: [
              Math.floor(seasonList['2024s1'].map(season => {
                const oneLength = fbState.totalData[season]?.one?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2024s1'].map(season => {
                const oneLength = fbState.totalData[season]?.seventy?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
              Math.floor(seasonList['2024s1'].map(season => {
                const oneLength = fbState.totalData[season]?.hundred?.length || 0;
                const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
                return percentage;
              }).reduce((acc, val) => acc + val, 0) / 8),
            ],
          },
        ]
        seasonChartCtx.update()
      } catch (error) { }
    }
  }, [chartThisData])

  const changeChartData = (seasonWeeks) => {
    try {
      setThisChartData([
        {
          label: '1회 이상',
          backgroundColor: "#a78bfa",
          borderColor: "#a78bfa",
          data: seasonWeeks.map(week => {
            const oneLength = fbState.totalData[week]?.one?.length || 0;
            const percentage = (oneLength / jsonUserCnt) * 100 || 0;
            return percentage;
          }),
          fill: false,
        },
        {
          label: '70% 이상',
          fill: false,
          backgroundColor: "#5eead4",
          borderColor: "#5eead4",
          data: seasonWeeks.map(week => {
            const oneLength = fbState.totalData[week]?.seventy?.length || 0;
            const percentage = (oneLength / jsonUserCnt) * 100 || 0;
            return percentage;
          }),
        },

        {
          label: '100% 달성',
          fill: false,
          backgroundColor: "#f43f5e",
          borderColor: "#f43f5e",
          data: seasonWeeks.map(week => {
            const oneLength = fbState.totalData[week]?.hundred?.length || 0;
            const percentage = (oneLength / jsonUserCnt) * 100 || 0;
            return percentage;
          }),
        },
      ])
    } catch (error) {
      console.log('changeChartData Error')
    }
  }
  //week Set Total
  const handlerTotalCsv = async (e) => {
    setDownloadButtonkHidden('hidden')
    setDownloadLinkHidden('')

    const toJson = await axios.get(process.env.PUBLIC_URL + "/2024s1.json")
      .then((res) => res.data)
      .catch((err) => err)

    dispatch(resetUserData())
    toJson.map((val) => {
      console.log(val.name, val.email)
      dispatch(readUser({ name: val.name, email: val.email }))
    })
  }

  useEffect(() => {
    axios.get(process.env.PUBLIC_URL + "/" + season + ".json")
      .then((res) => {
        console.log('res', res)
        console.log(seasonList[season][week])
        setJson(res.data)
        setJsonUserCnt(res.data.length)
        console.log("res.data.length", res.data.length)
        return res.data
      })
      .catch((err) => {
        console.log('err', err)
        return err
      })
  }, [week, season])

  useEffect(() => {
    setWeekTotal(seasonList[season])
  }, [week, fbState])

  const setWeekTotal = (seasonWeeks) => {
    if (!fbState.loading) {
      const percentageOne = seasonWeeks.map(season => {
        const oneLength = fbState.totalData[season]?.one?.length || 0;
        const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
        return percentage;
      });
      const percentageSeventy = seasonWeeks.map(season => {
        const oneLength = fbState.totalData[season]?.seventy?.length || 0;
        const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
        return percentage;
      });
      const percentageHundred = seasonWeeks.map(season => {
        const oneLength = fbState.totalData[season]?.hundred?.length || 0;
        const percentage = Math.floor((oneLength / jsonUserCnt) * 100) || 0;
        return percentage;
      });

      let tempOneAll = 0
      let tempSeventyAll = 0
      let tempHundredAll = 0
      try {
        setOne(percentageOne[week])
        setSeventy(percentageSeventy[week])
        setHundred(percentageHundred[week])

        setOnePre(percentageOne[week - 1 < 0 ? 0 : week - 1])
        setSeventyPre(percentageSeventy[week - 1 < 0 ? 0 : week - 1])
        setHundredPre(percentageHundred[week - 1 < 0 ? 0 : week - 1])

        tempOneAll = percentageOne.slice(0, (week + 1)).reduce((acc, val) => acc + val, 0);
        tempSeventyAll = percentageSeventy.slice(0, (week + 1)).reduce((acc, val) => acc + val, 0);
        tempHundredAll = percentageHundred.slice(0, (week + 1)).reduce((acc, val) => acc + val, 0);

        setOneAll(Math.floor(tempOneAll / (week + 1)))
        setSeventyAll(Math.floor(tempSeventyAll / (week + 1)))
        setHundredAll(Math.floor(tempHundredAll / (week + 1)))
        console.log('setWeekTotal Error', tempHundredAll, tempHundredAll / (week + 1), week)
      } catch {
        console.log('setWeekTotal Error')
      }
    }
  }

  const handlerChangeWeek = (e) => {
    if (!fbState.loading) {
      if (e.target.value === "now") {
        setWeek(0)
      } else {
        setWeek(Number(e.target.value))
      }
    }
  }

  const handlerChangeSeason = (e) => {
    if (!fbState.loading) {
      console.log(e.target.value)
      switch (e.target.value) {
        case '0':
          setSeason('2022s1')
          changeChartData(seasonList['2022s1'])
          setWeekTotal(seasonList['2022s1'])
          break;
        case '1':
          setSeason('2022s2')
          changeChartData(seasonList['2022s2'])
          setWeekTotal(seasonList['2022s2'])
          break;
        case '2':
          setSeason('2022s3')
          changeChartData(seasonList['2022s3'])
          setWeekTotal(seasonList['2022s3'])
          break;
        case '3':
          setSeason('2023s1')
          changeChartData(seasonList['2023s1'])
          setWeekTotal(seasonList['2023s1'])
          break;
        case '4':
          setSeason('2023s2')
          changeChartData(seasonList['2023s2'])
          setWeekTotal(seasonList['2023s2'])
          break;
        case '5':
          setSeason('2023s3')
          changeChartData(seasonList['2023s3'])
          setWeekTotal(seasonList['2023s3'])
          break;
        case '6':
          setSeason('2024s1')
          changeChartData(seasonList['2024s1'])
          setWeekTotal(seasonList['2024s1'])
          break;
      }
    }
  }

  return (
    <>
      {!fbState.loading ?
        <div className="w-full h-full">
          <div className="p-4">
            <div className="flex justify-center">
              <span className="t font-bold text-center">5시간 챌린지 현황 다운로드</span>
            </div>
            <div className="flex space-x-2 justify-center">
              <button type="button" onClick={handlerTotalCsv}
                className={"inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out " + downloadButtonkHidden}>
                다운로드
              </button>
              <a download={'5시간 챌린지.csv'} onClick={() => {
                setDownloadLinkHidden('hidden')
                setDownloadButtonkHidden('')
              }} href={downloadLink} className={"inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out " + downloadLinkHidden}>
                확인
              </a>
            </div>
          </div>
          <hr />

          {/* 시즌 비교 */}
          <div className="p-4">
            <h1 className="text-l font-semibold text-center text-gray-500 hover:text-gray-900">시즌 비교 차트</h1>
            <div className="px-8 flex-auto">
              <canvas id="seasonChart" width="400" height="400"></canvas>
            </div>
          </div>

          <hr />
          {/* 주간 선택 */}
          <div className="flex justify-center p-4 bg-slate-100">
            <select name="season" id="season" className="bg-transparent w-28 text-l font-semibold text-center text-gray-500" onChange={handlerChangeSeason}>
              <option value="now">시즌</option>
              <option value="0">2022여름</option>
              <option value="1">2022가을</option>
              <option value="2">2022겨울</option>
              <option value="3">2023봄</option>
              <option value="4">2023여름</option>
              <option value="5">2023가을</option>
              <option value="6">2024겨울</option>
            </select>
            <hr className="w-4 border-none" />
            <select name="weeks" id="weeks" className="bg-transparent w-28 text-l font-semibold text-center text-gray-500" onChange={handlerChangeWeek}>
              <option value="now">주간</option>
              <option value="0">1주</option>
              <option value="1">2주</option>
              <option value="2">3주</option>
              <option value="3">4주</option>
              <option value="4">5주</option>
              <option value="5">6주</option>
              <option value="6">7주</option>
              <option value="7">8주</option>
            </select>
          </div>
          <hr />

          {/* 전체 카드 */}
          <div className="p-4">
            <h1 className="text-l font-semibold text-center text-gray-500 hover:text-gray-900">전체 달성률</h1>
            <div className="px-4 md:px-10 mx-auto w-full">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                  <StatsCard
                    statSubtitle="1회 이상 참여"
                    statTitle={oneAll}
                    statSrc={ImgOne}
                    statNone={true}
                  />
                </div>

                <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                  <StatsCard
                    statSubtitle="70% 이상 달성"
                    statTitle={seventyAll}
                    statSrc={ImgTwo}
                    statNone={true}
                  />
                </div>

                <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                  <StatsCard
                    statSubtitle="100% 달성"
                    statTitle={hundredAll}
                    statSrc={ImgThree}
                    statNone={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />

          {/* 주간 카드 */}
          <div className="p-4">
            <h1 className="text-l font-semibold text-center text-gray-500 hover:text-gray-900">주간 달성률</h1>
            <div className="px-4 md:px-10 mx-auto w-full">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                  <StatsCard
                    statSubtitle="1회 이상 참여"
                    statTitle={one}
                    statArrow={one === onePre ? "none" : one >= onePre ? "up" : "down"}
                    statPercent={Math.abs(one - onePre)}
                    statPercentColor={one === onePre ? "" : one >= onePre ? "text-emerald-500" : "text-rose-500"}
                    statDescripiron="지난 주"
                    statSrc={ImgOne}
                  />
                </div>

                <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                  <StatsCard
                    statSubtitle="70% 이상 달성"
                    statTitle={seventy}
                    statArrow={seventy === seventyPre ? "" : seventy >= seventyPre ? "up" : "down"}
                    statPercent={Math.abs(seventy - seventyPre)}
                    statPercentColor={seventy === seventyPre ? "" : seventy >= seventyPre ? "text-emerald-500" : "text-rose-500"}
                    statDescripiron="지난 주"
                    statSrc={ImgTwo}
                  />
                </div>

                <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                  <StatsCard
                    statSubtitle="100% 달성"
                    statTitle={hundred}
                    statArrow={hundred === hundredPre ? "" : hundred >= hundredPre ? "up" : "down"}
                    statPercent={Math.abs(hundred - hundredPre)}
                    statPercentColor={hundred === hundredPre ? "" : hundred >= hundredPre ? "text-emerald-500" : "text-rose-500"}
                    statDescripiron="지난 주"
                    statSrc={ImgThree}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />

          {/* 차트 */}
          <div className="p-4">
            <h1 className="text-l font-semibold text-center text-gray-500 hover:text-gray-900">시즌 차트</h1>
            <div className="px-8 flex-auto">
              <canvas id="thisChart" width="400" height="400"></canvas>
            </div>
          </div>
          <UserList totalData={fbState.totalData[seasonList[season][week]]} json={json} />
        </div>
        : <></>}
    </>
  );
}

export default React.memo(StatsBoard);
