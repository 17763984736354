
export const addOneMonthToDate = (date) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 1);

  // 월의 마지막 날짜 구하기
  const lastDay = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();

  // 현재 날짜의 일 수가 월의 마지막 날짜보다 크다면 마지막 날짜로 설정
  if (date.getDate() > lastDay) {
    newDate.setDate(lastDay);
  }

  return newDate.toISOString().slice(0, 10);
}

export const toDateFormat = (date) => {
  return date.toISOString().slice(0, 10);
}

export const isExpirationDate = (paymentDate, expirationDate) => {
  try {
    return toDateFormat(new Date(expirationDate.seconds * 1000))
  } catch (error) {
    return addOneMonthToDate(new Date(paymentDate.seconds * 1000))
  }
}
