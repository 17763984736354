import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { readCamp, uploadCamp } from "../modules/firebase";

const CampApply = () => {
  const dispat = useDispatch()
  const fbState = useSelector((state) => state.firebase);
  const [list, setList] = useState(<></>)
  const [check, setCheck] = useState([])
  useEffect(() => {
    dispat(readCamp())
  }, []);

  useEffect(() => {
    var tempList = []
    if (!fbState.loading) {
      console.log('fbState')
      fbState.camp.map((camp, idx) => {
        check[idx] = camp.done
        tempList.push(
          <tr className="bg-white border-b">
            <th scope="row" className="p-4 text-center text-gray-900whitespace-nowrap">
              {camp.code === 100 ? "박창현" : "안후선"}
            </th>
            <th scope="row" className="p-4 text-center text-gray-900whitespace-nowrap">
              {camp.day}
            </th>
            <td className="p-4">
              <div className="relative h-10 flex justify-center -translate-x-10">
                <label className="cursor-pointer">
                  <input className="peer hidden" type="checkbox" checked={check[idx]} onClick={() => handlerCheck(idx)} />
                  <div className="flex absolute items-center gap-2 rounded-lg peer-checked:bg-red-500 bg-emerald-500 duration-200 peer-checked:opacity-0 opacity-100 py-2 px-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                    <div className="text-white font-semibold">신청</div>
                  </div>
                  <div className="flex absolute items-center gap-2 rounded-lg peer-checked:bg-red-500 bg-emerald-500 duration-200 peer-checked:opacity-100 opacity-0 py-2 px-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <div className="text-white font-semibold">마감</div>
                  </div>
                </label>
              </div>
            </td>
          </tr>
        )
      })

      setList(tempList)
    }
  }, [fbState])

  useEffect(() => {
    var tempList = []
    if (!fbState.loading) {
      console.log('fbState')
      fbState.camp.map((camp, idx) => {
        tempList.push(
          <tr className="bg-white border-b">
            <th scope="row" className="p-4 text-center text-gray-900whitespace-nowrap">
              {camp.code === 100 ? "박창현" : "안후선"}
            </th>
            <th scope="row" className="p-4 text-center text-gray-900whitespace-nowrap">
              {camp.day}
            </th>
            <td className="p-4">
              <div className="relative h-10 flex justify-center -translate-x-10">
                <label className="cursor-pointer">
                  <input className="peer hidden" type="checkbox" checked={check[idx]} onClick={() => handlerCheck(idx)} />
                  <div className="flex absolute items-center gap-2 rounded-lg peer-checked:bg-red-500 bg-emerald-500 duration-200 peer-checked:opacity-0 opacity-100 py-2 px-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                    <div className="text-white font-semibold">신청</div>
                  </div>
                  <div className="flex absolute items-center gap-2 rounded-lg peer-checked:bg-red-500 bg-emerald-500 duration-200 peer-checked:opacity-100 opacity-0 py-2 px-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <div className="text-white font-semibold">마감</div>
                  </div>
                </label>
              </div>
            </td>
          </tr>
        )
      })

      setList(tempList)
    }
  }, [check])

  const handlerCheck = (idx) => {
    var tempCheck = [...check]
    tempCheck[idx] = !tempCheck[idx]
    setCheck(tempCheck)
  }
  const handlerUpload = () => {
    var tempCamp = [
    ]
    console.log('upload')
    check.map((camp, idx) => {
      tempCamp.push({
        'day': fbState.camp[idx].day,
        'done': camp,
        'code': fbState.camp[idx].code
      })
    })

    const blob = new Blob([JSON.stringify(tempCamp)], { type: 'application/json', filename: "camp.json" });

    console.log(blob)
    dispat(uploadCamp({ file: blob }))
  }
  return (
    <>
      {!fbState.loading ?
        <>
          <div className="relative shadow-md sm:rounded-lg m-8">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className=" text-gray-700 bg-gray-50">
                <tr>
                  <th scope="col" className="w-1/3 text-center px-4 py-3">
                    담당자
                  </th>
                  <th scope="col" className="w-1/3 text-center px-4 py-3">
                    요일
                  </th>
                  <th scope="col" className="w-1/3 text-center px-4 py-3">
                    구분
                  </th>
                </tr>
              </thead>
              <tbody>
                {list}
              </tbody>
            </table>
          </div>
          <div className="flex space-x-2 justify-center">
            <button type="button" onClick={handlerUpload} className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">업데이트</button>
          </div>
        </>
        : <></>}
    </>
  );
}


export default React.memo(CampApply);