import React from "react"
import CampApply from "../components/CampApply";
import Menu from "../components/Menu";
const CampApplyPage = () => {
  return (
    <>
      <div >
        <CampApply />
      </div>
    </>
  );
}


export default React.memo(CampApplyPage);