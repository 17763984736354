
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import BoardPage from './pages/BoardPage';
import CampApplyPage from './pages/CampApplyPage';
import PlanPage from './pages/PlanPage';
import SignPage from './pages/SignPage';
import VipMembersPage from './pages/VipMembersPage';
import AttendancePage from './pages/AttendancePage';
import LaboratoryPage from './pages/LaboratoryPage';
import Menu from './components/Menu';

const App = () => {
  const signState = useSelector((state) => state.firebase.signState);
  console.log('singState', signState, signState.signState)
  useEffect(() => {
    return () => {
      localStorage.clear();
    }
  }, [])

  return (
    <>
      {signState ?
        (
          <div >
            <HashRouter >
              <Menu />
              <Routes>
                <Route path="/" element={<BoardPage />} />
                <Route path="/vip" element={<VipMembersPage />} />
                <Route path="/camp" element={<CampApplyPage />} />
                <Route path="/plan" element={<PlanPage />} />
                <Route path="/attendance" element={<AttendancePage />} />
                <Route path="/laboratory" element={<LaboratoryPage />} />
              </Routes>
            </HashRouter >
          </div >
        ) : (
          <>
            <SignPage />
          </>
        )
      }
    </>

  );
}



export default React.memo(App);
